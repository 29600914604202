import React, { useRef } from 'react';
import OtpInput from '@keepertax/react-otp-input';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { validateLoginOtp } from '@app/src/services/workService';
import '@app/src/Components/Login/LoginWithOTP/LoginOtp.scss';

const LoginOtp = ({ className, type, id, otp, onOtpChange, validateLoginOtp, onSuccess }) => {
  const otpRef = useRef(null);

  const validateOtp = async (otp) => {
    const valid = await validateLoginOtp(
      {
        ...(type === 'phone' ? { phone: id } : { email: id }),
        token: parseInt(otp, 10)
      },
      onSuccess
    );

    return valid;
  };

  const onOtpInputChange = async (value) => {
    onOtpChange(value);

    if (value.length === 6) {
      otpRef.current.blurInput();
      const validOtp = await validateOtp(value);

      if (!validOtp) {
        onOtpChange('');
        otpRef.current.focusInput(0);
      }
    }
  };

  return (
    <div className='login-otp-container'>
      <div className='login-otp-content'>
        <div className='login-otp-label'>Security code</div>
        <OtpInput
          className={classNames('login-otp', className)}
          ref={otpRef}
          inputStyle='login-otp-input dd-privacy-mask-user-input browser-default'
          focusStyle='login-otp-focus'
          containerStyle='login-otp-input-container'
          onChange={onOtpInputChange}
          numInputs={6}
          clearTextOnFocus
          isInputNum
          value={otp}
          shouldAutoFocus
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  validateLoginOtp
};

const ConnectedLoginOtp = connect(null, mapDispatchToProps)(LoginOtp);

export default ConnectedLoginOtp;
