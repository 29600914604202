// path components
export const PATH_COMPONENT__SUBMIT_PIN = 'submit-pin';
export const PATH_COMPONENT__SUBMIT_DEBIT = 'submit-debit';
export const PATH_COMPONENT__SUBMIT_DEBIT_MANUAL = 'submit-debit-manual';
export const PATH_COMPONENT__SUBMIT_BANK_NUMBERS = 'submit-bank-numbers';
export const PATH_COMPONENT__SUBMIT_SIGNATURE = 'submit-signature';
export const PATH_COMPONENT__SUBMIT_EMAIL_INFO = 'submit-email-info';
export const PATH_COMPONENT__SUBMIT_CONFIRMATION = 'submit-confirmation';
export const PATH_COMPONENT__SUBMIT_CONFIRM_ID = 'submit-confirm-id';
export const PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO = 'submit-confirm-id-intro';

// slugs
export const SLUG__SUBMIT_PIN = 'submit-pin';
export const SLUG__SUBMIT_HAVE_PIN = 'submit-have-pin';
export const SLUG__SUBMIT_SELF_PIN_NUMBER = 'submit-self-pin-number';
export const SLUG__SUBMIT_SPOUSE_PIN_NUMBER = 'submit-spouse-pin-number';
export const SLUG__SUBMIT_DEBIT = 'submit-debit';
export const SLUG__SUBMIT_DEBIT_MANUAL_HEADER = 'submit-debit-manual-header';
export const SLUG__SUBMIT_DEBIT_MANUAL_OPTION = 'submit-debit-manual-option';
export const SLUG__SUBMIT_DEBIT_INSTITUTION = 'submit-debit-institution';
export const SLUG__SUBMIT_DEBIT_ACCOUNT = 'submit-debit-account';
export const SLUG__SUBMIT_DEBIT_TYPE = 'submit-debit-type';
export const SLUG__SUBMIT_DEBIT_MANUAL = 'submit-debit-manual';
export const SLUG__SUBMIT_BANK_NUMBERS = 'submit-bank-numbers';
export const SLUG__SUBMIT_DEBIT_ROUTING = 'submit-debit-routing';
export const SLUG__SUBMIT_SIGNATURE = 'submit-signature';
export const SLUG__SUBMIT_SIGNATURE_SELF = 'submit-signature-self';
export const SLUG__SUBMIT_SIGNATURE_SPOUSE = 'submit-signature-spouse';
export const SLUG__SUBMIT_CONFIRMATION = 'submit-confirmation';
export const SLUG__SUBMIT_CONFIRMATION_MODAL = 'submit-confirmation-modal';
export const SLUG__SUBMIT_EMAIL_INFO = 'submit-email-info';
export const SLUG__SUBMIT_CONFIRM_ID = 'submit-confirm-id';
export const SLUG__SUBMIT_CONFIRM_ID_INTRO = 'submit-confirm-id-intro';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_PAYMENT_METHOD = 'submit-debit-payment-method';
export const ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT = 'submit-subscription-prompt';
export const ENDPOINT_ATTRIBUTE__SUBMIT_EMAIL_INFO = 'submit-email-info';
export const ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT = 'submit-debit-account';
export const ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_ACCOUNT_ID = 'submit-debit-account-id';
export const ENDPOINT_ATTRIBUTE__SUBMIT_DEBIT_MANUAL_OPTION = 'submit-debit-manual-option';
export const ENDPOINT_ATTRIBUTE__SUBMIT_SIGNATURE_SELF = 'submit-signature-self';
export const ENDPOINT_ATTRIBUTE__SUBMIT_SIGNATURE_SPOUSE = 'submit-signature-spouse';

// collection types
export const COLLECTION_TYPE__SUBMIT_DEBIT = 'submit-debit';
export const COLLECTION_TYPE__SUBMIT_EMAIL_INFO = 'submit-email-info';
export const COLLECTION_TYPE__SUBMIT_SIGNATURE = 'submit-signature';
export const COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT = 'submit-subscription-prompt';

// submit audit types
export const SUBMIT_AUDIT_TYPE = 'submit-audit-type';
export const SUBMIT_AUDIT_ISSUE = 'submit-audit-issue';
