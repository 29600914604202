import React from 'react';
import Icon from '@mui/material/Icon';
import classNames from 'classnames';
import ExpenseLinkIcon from '@app/src/assets/expense-link.svg?react';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/Common/LinkedLockoutInput/LinkedLockoutInput.scss';

const LinkedLockoutInput = ({ className, label, value, onClick, lightExplainerText, analyticsType }) => {
  const onLinkClick = () => {
    trackActivity('link out: clicked', { linkOut: label, default: analyticsType });
    onClick();
  };

  return (
    <div className={classNames('linked-lockout-body', className)}>
      <label className='linked-lockout-label'>{label}</label>
      <button className='linked-lockout-item' onClick={onLinkClick}>
        <div className='linked-lockout-item-text'>
          <span>
            {value}
            <span className='linked-lockout-item-text-light'>{lightExplainerText}</span>
          </span>
        </div>
        <Icon className='linked-lockout-item-icon'>
          <ExpenseLinkIcon width={22} height={22} />
        </Icon>
      </button>
    </div>
  );
};

export default LinkedLockoutInput;
