import { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  useGetCollectionTypeItemsQuery,
  useGetCurrentQuestionnaireQuestionQuery,
  useGetTaxDataQuery,
  useGetUIStageQuery
} from '@app/src/api/taxDataApi';
import { getIsTaxFilingSandwiched, sentMsgToReactNative } from '@app/src/global/Helpers';
import { useIsQuestionnaireFlow } from '@app/src/hooks/useIsQuestionnaireFlow';
import { allQuestionsSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getSlugByLocation } from '@app/src/taxflow/main/utils/mainUtils';
import {
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_MULTI_IMAGE,
  SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
  SLUG__CREDIT_ADD_MORE,
  SLUG__INCOME_ADD_MORE
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  SLUG__SUBMIT_CONFIRM_ID_INTRO,
  SLUG__SUBMIT_CONFIRM_ID
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  STATE_TAX_COLL_TYPE_MAP,
  STATE_NAME_MAP,
  UI_STAGE__CONFIRM_AMOUNTS
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentCollectionIdSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const SLUG_TO_HEADER_TEXT = {
  [SLUG__BULK_UPLOAD_MANUAL_ENTRY]: 'Add tax form',
  [SLUG__BULK_UPLOAD_MULTI_IMAGE]: 'Add tax form',
  [SLUG__BULK_UPLOAD_PHOTO_CAPTURE]: 'Add tax form',
  [SLUG__INCOME_ADD_MORE]: 'Add income',
  [SLUG__CREDIT_ADD_MORE]: 'Add credits',
  [SLUG__SUBMIT_CONFIRM_ID_INTRO]: 'Verification',
  [SLUG__SUBMIT_CONFIRM_ID]: 'Verification'
};

const useHeaders = () => {
  const location = useLocation();
  const isQuestionnaireFlow = useIsQuestionnaireFlow();
  const allQuestions = useSelector(allQuestionsSelector);
  const currentCollectionId = useSelector(currentCollectionIdSelector);
  const isFilingSandwiched = getIsTaxFilingSandwiched();
  const year = useSelector(yearSelector);

  const { data: currentQuestionnaireQuestion, isFetching: currentQuestionnaireQuestionFetching } =
    useGetCurrentQuestionnaireQuestionQuery({ year });
  const { data: uiStage, isFetching: uiStageFetching } = useGetUIStageQuery({ year });
  const { data: collectionTypeItems, isFetching: collectionTypeItemsFetching } = useGetCollectionTypeItemsQuery({
    year
  });

  const slug = getSlugByLocation(location);
  const collectionId = isQuestionnaireFlow ? currentQuestionnaireQuestion?.coll_id : currentCollectionId;
  const currentQuestion = _.find(allQuestions, { slug });
  const collectionType = isQuestionnaireFlow
    ? currentQuestionnaireQuestion?.coll_type
    : currentQuestion?.collectionType;

  const { data: taxData, isFetching: taxDataFetching } = useGetTaxDataQuery({
    year
  });

  const [headerText, setHeaderText] = useState();
  const [secondaryHeaderText, setSecondaryHeaderText] = useState();

  /**
   * Update the header and secondary header as a function of whether the page is part of the questionnaire flow
   */
  const setHeaders = useCallback(
    (title) => {
      if (isQuestionnaireFlow) {
        setHeaderText('Questionnaire');
        setSecondaryHeaderText(title);
      } else {
        setHeaderText(title);
        setSecondaryHeaderText(null);
      }
    },
    [isQuestionnaireFlow]
  );

  useEffect(() => {
    if (!isFilingSandwiched) {
      return;
    }
    // Find field pillDisplayPreface for the collection type item respective of the current flow
    // This will serve as the header text in most cases
    const collectionTypeDisplayName = _.chain(collectionTypeItems)
      .filter({ collectionType })
      .find(({ subTypeDifferentiationSlug, subTypeDifferentiationValue }) => {
        const subType = _.chain(taxData)
          .find({ slug: subTypeDifferentiationSlug, coll_id: collectionId })
          .get('value')
          .value();
        return (
          _.isNil(subTypeDifferentiationSlug) ||
          subType === subTypeDifferentiationValue ||
          (_.isEmpty(subType) && _.isEmpty(subTypeDifferentiationValue))
        );
      })
      .get('pillDisplayPreface')
      .value();
    const maybeState = _.chain(STATE_TAX_COLL_TYPE_MAP)
      .toPairs()
      .find(([, collectionTypes]) => _.includes(collectionTypes, collectionType))
      .get('0')
      .value();

    // Case: in the confirm amounts sub-flow, always display "Sign & file"
    if (uiStage === UI_STAGE__CONFIRM_AMOUNTS) {
      setHeaders('Sign & file');
    }
    // Case: we have provided a hardcoded value for the header of the given question
    // This is useful for questions which don't have a respective encompassing collection type item, usually those belonging to the "special" collection type
    else if (_.has(SLUG_TO_HEADER_TEXT, slug)) {
      setHeaders(_.get(SLUG_TO_HEADER_TEXT, slug));
    }
    // Case: question is part of a states flow
    // Render the state name as the header
    else if (!_.isEmpty(maybeState)) {
      setHeaders(`State return: ${_.get(STATE_NAME_MAP, maybeState)}`);
    }
    // Case: We don't have enough info to decide on the header yet. Do nothing
    else if (
      currentQuestionnaireQuestionFetching ||
      uiStageFetching ||
      collectionTypeItemsFetching ||
      taxDataFetching ||
      _.isNil(collectionType) ||
      _.isNil(collectionId)
    ) {
      return;
    }
    // Case: question is part of the questionnaire flow.
    else if (isQuestionnaireFlow) {
      setHeaders(
        currentQuestionnaireQuestion?.type === 'form-review' ? 'Form review' : collectionTypeDisplayName ?? 'General'
      );
    }
    // Case: Use contentful-maintained CollectionTypeItems to derive the header name.
    // This is the case for almost all sub-flows (those not encompassed by special cases above)
    else if (!_.isNil(collectionTypeDisplayName)) {
      setHeaders(collectionTypeDisplayName);
    } else {
      setHeaders(null);
      defaultCaptureMessage('[unexpected_edge_case] could not get question header', {
        slug
      });
    }
  }, [
    isFilingSandwiched,
    uiStage,
    isQuestionnaireFlow,
    collectionTypeItems,
    collectionId,
    taxData,
    collectionType,
    slug,
    currentQuestionnaireQuestion,
    currentQuestionnaireQuestionFetching,
    uiStageFetching,
    collectionTypeItemsFetching,
    taxDataFetching,
    setHeaders
  ]);

  return { headerText, secondaryHeaderText };
};

/**
 * Tells the app to render the native tax filing header
 */
export const TaxFlowQuestionHeader = () => {
  const { headerText, secondaryHeaderText } = useHeaders();

  useEffect(() => {
    if (!_.isNil(headerText)) {
      sentMsgToReactNative('set_tax_header', { type: 'question', headerText, secondaryHeaderText });
    }
  }, [headerText, secondaryHeaderText]);

  return null;
};
