import _ from 'lodash';
import {
  PATH_COMPONENT__CREDIT_CHARITY_AMOUNT,
  PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL,
  PATH_COMPONENT__CREDIT_ENERGY_INFO,
  PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
  PATH_COMPONENT__CREDIT_HSA_DETAIL,
  PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  PATH_COMPONENT__CREDIT_TEACHING_INFO
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { INCOME_PATH_COMPONENTS } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  PATH_COMPONENT__SPECIAL_EARLY_EXIT,
  SLUG__SPECIAL_EARLY_EXIT,
  PATH_COMPONENT__FIND_WRITE_OFFS,
  SLUG__FIND_WRITE_OFFS,
  PATH_COMPONENT__BULK_UPLOAD_QUESTIONS,
  SLUG__BULK_UPLOAD_QUESTIONS,
  PATH_COMPONENT__PREMIUM_START,
  SLUG__PREMIUM_START,
  PATH_COMPONENT__MATCHING,
  SLUG__MATCHING,
  PATH_COMPONENT__MATCHING_COMPLETE,
  SLUG__MATCHING_COMPLETE,
  PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE,
  SLUG__BULK_UPLOAD_MULTI_IMAGE,
  PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE,
  SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
  PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_HAS_1099,
  SLUG__BULK_UPLOAD_HAS_W2,
  SLUG__BULK_UPLOAD_HAS_1099G,
  SLUG__BULK_UPLOAD_HAS_INT,
  SLUG__BULK_UPLOAD_HAS_DIV,
  SLUG__BULK_UPLOAD_HAS_INVEST,
  SLUG__BULK_UPLOAD_HAS_RETIREMENT,
  SLUG__BULK_UPLOAD_HAS_W2G,
  SLUG__BULK_UPLOAD_HAS_HSA,
  SLUG__BULK_UPLOAD_HAS_IRA,
  SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS,
  SLUG__BULK_UPLOAD_HAS_MORTGAGE,
  SLUG__BULK_UPLOAD_HAS_ENERGY,
  SLUG__BULK_UPLOAD_HAS_TEACHING,
  SLUG__BULK_UPLOAD_HAS_TUITION,
  SLUG__BULK_UPLOAD_HAS_CHILDCARE,
  SLUG__BULK_UPLOAD_HAS_CHARITY,
  PATH_COMPONENT__BULK_UPLOAD_HAS_1099,
  PATH_COMPONENT__BULK_UPLOAD_HAS_W2,
  PATH_COMPONENT__BULK_UPLOAD_HAS_1099G,
  PATH_COMPONENT__BULK_UPLOAD_HAS_INT,
  PATH_COMPONENT__BULK_UPLOAD_HAS_DIV,
  PATH_COMPONENT__BULK_UPLOAD_HAS_INVEST,
  PATH_COMPONENT__BULK_UPLOAD_HAS_RETIREMENT,
  PATH_COMPONENT__BULK_UPLOAD_HAS_W2G,
  PATH_COMPONENT__BULK_UPLOAD_HAS_HSA,
  PATH_COMPONENT__BULK_UPLOAD_HAS_IRA,
  PATH_COMPONENT__BULK_UPLOAD_HAS_STUDENT_LOANS,
  PATH_COMPONENT__BULK_UPLOAD_HAS_MORTGAGE,
  PATH_COMPONENT__BULK_UPLOAD_HAS_ENERGY,
  PATH_COMPONENT__BULK_UPLOAD_HAS_TEACHING,
  PATH_COMPONENT__BULK_UPLOAD_HAS_TUITION,
  PATH_COMPONENT__BULK_UPLOAD_HAS_CHARITY,
  PATH_COMPONENT__BULK_UPLOAD_HAS_CHILDCARE,
  PATH_COMPONENT__INCOME_ADD_MORE,
  SLUG__INCOME_ADD_MORE,
  PATH_COMPONENT__CREDIT_ADD_MORE,
  SLUG__CREDIT_ADD_MORE,
  PATH_COMPONENT__TAX_HOME
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__MATCHING]: {
      nextPathComponent: PATH_COMPONENT__MATCHING_COMPLETE,
      questionnaireEarlyExit: true
    },
    [SLUG__MATCHING_COMPLETE]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: true
    },
    [SLUG__BULK_UPLOAD_MULTI_IMAGE]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: true
    },
    [SLUG__BULK_UPLOAD_PHOTO_CAPTURE]: {
      nextPathComponent: PATH_COMPONENT__TAX_HOME,
      questionnaireEarlyExit: true
    }
  };
};

export const getOptionPathComponentMap = () => {
  return {
    [SLUG__BULK_UPLOAD_HAS_1099]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_1099_TYPE,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_W2]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.W2_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_1099G]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.UNEMPLOYMENT_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_INT]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.INTEREST_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_DIV]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.DIV_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_INVEST]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.INVEST_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_RETIREMENT]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.RETIREMENT_TYPE,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_W2G]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: INCOME_PATH_COMPONENTS.W2G_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_HSA]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_HSA_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_IRA]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_MORTGAGE]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_ENERGY]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_ENERGY_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_TEACHING]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_TEACHING_INFO,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_TUITION]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_CHILDCARE]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__BULK_UPLOAD_HAS_CHARITY]: {
      0: {
        questionnaireEarlyExit: true
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_CHARITY_AMOUNT,
        questionnaireEarlyExit: false
      }
    }
  };
};
export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__SPECIAL_EARLY_EXIT]: SLUG__SPECIAL_EARLY_EXIT,
    [PATH_COMPONENT__FIND_WRITE_OFFS]: SLUG__FIND_WRITE_OFFS,
    [PATH_COMPONENT__PREMIUM_START]: SLUG__PREMIUM_START,
    [PATH_COMPONENT__BULK_UPLOAD_QUESTIONS]: SLUG__BULK_UPLOAD_QUESTIONS,
    [PATH_COMPONENT__BULK_UPLOAD_MULTI_IMAGE]: SLUG__BULK_UPLOAD_MULTI_IMAGE,
    [PATH_COMPONENT__BULK_UPLOAD_PHOTO_CAPTURE]: SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
    [PATH_COMPONENT__BULK_UPLOAD_MANUAL_ENTRY]: SLUG__BULK_UPLOAD_MANUAL_ENTRY,
    [PATH_COMPONENT__MATCHING]: SLUG__MATCHING,
    [PATH_COMPONENT__MATCHING_COMPLETE]: SLUG__MATCHING_COMPLETE,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_1099]: SLUG__BULK_UPLOAD_HAS_1099,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_W2]: SLUG__BULK_UPLOAD_HAS_W2,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_1099G]: SLUG__BULK_UPLOAD_HAS_1099G,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_INT]: SLUG__BULK_UPLOAD_HAS_INT,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_DIV]: SLUG__BULK_UPLOAD_HAS_DIV,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_INVEST]: SLUG__BULK_UPLOAD_HAS_INVEST,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_RETIREMENT]: SLUG__BULK_UPLOAD_HAS_RETIREMENT,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_W2G]: SLUG__BULK_UPLOAD_HAS_W2G,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_HSA]: SLUG__BULK_UPLOAD_HAS_HSA,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_IRA]: SLUG__BULK_UPLOAD_HAS_IRA,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_STUDENT_LOANS]: SLUG__BULK_UPLOAD_HAS_STUDENT_LOANS,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_MORTGAGE]: SLUG__BULK_UPLOAD_HAS_MORTGAGE,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_ENERGY]: SLUG__BULK_UPLOAD_HAS_ENERGY,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_TEACHING]: SLUG__BULK_UPLOAD_HAS_TEACHING,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_TUITION]: SLUG__BULK_UPLOAD_HAS_TUITION,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_CHARITY]: SLUG__BULK_UPLOAD_HAS_CHARITY,
    [PATH_COMPONENT__BULK_UPLOAD_HAS_CHILDCARE]: SLUG__BULK_UPLOAD_HAS_CHILDCARE,
    [PATH_COMPONENT__INCOME_ADD_MORE]: SLUG__INCOME_ADD_MORE,
    [PATH_COMPONENT__CREDIT_ADD_MORE]: SLUG__CREDIT_ADD_MORE
  };
};

export const getClarifyingQuestionHeader = ({
  currentQuestion,
  currentCollectionId,
  currentQuestionnaireQuestion,
  bulkUploadPills
}) => {
  const currentCollectionType = _.get(currentQuestion, 'collectionType');
  const maybeBulkUploadPillForForm = _.find(bulkUploadPills, {
    collectionType: currentCollectionType,
    collectionId: `${currentCollectionId}`
  });

  if (!_.isNil(maybeBulkUploadPillForForm)) {
    const isFormReviewScreen = _.get(currentQuestionnaireQuestion, 'type') === 'form-review';
    const formName = _.get(maybeBulkUploadPillForForm, 'text');

    if (_.isNil(formName)) {
      defaultCaptureMessage('Could not get bulk upload data for form', {
        coll_type: currentCollectionType,
        sub_type: maybeBulkUploadPillForForm.subType,
        coll_id: currentCollectionId
      });
      return currentQuestion.title;
    }

    return isFormReviewScreen ? `Let's review your ${formName}` : `${formName}\n${currentQuestion.title}`;
  }

  return currentQuestion.title;
};
