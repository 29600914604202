import { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useEvent from 'react-use-event-hook';
import { PATH_COMPONENT__TAX_HOME } from '@app/src//taxflow/sections/special/constants/specialConstants';
import { useGetCollectionTypeItemsQuery } from '@app/src/api/taxDataApi';
import { useIsQuestionnaireFlow } from '@app/src/hooks/useIsQuestionnaireFlow';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import { COLLECTION_TYPE__HOME } from '@app/src/taxflow/sections/home/homeConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  contextLoadingSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  updatingSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

// TODO update check to work for these coll types
// These are a mixed bag of "singular" and "plural" pages. We need to differentiate between such
const IGNORE_COLLECTION_TYPES = [COLLECTION_TYPE__CAR, COLLECTION_TYPE__HOME];

const isStateQuestionPlural = (currentQuestion) => currentQuestion.slug === SLUG__STATE_RETURN;

/**
 * Reroute to home if url parameter collectionId is missing / invalid
 */
export const useRedirectOnInvalidCollectionId = () => {
  const location = useLocation();
  const history = useHistory();
  const year = useSelector(yearSelector);
  const { data: collectionTypeItems, isLoading: collectionTypeItemsLoading } = useGetCollectionTypeItemsQuery({ year });
  const pageLoading = useSelector(contextLoadingSelector);
  const pageUpdating = useSelector(updatingSelector);
  const currentQuestion = useSelector(currentQuestionSelector);
  const currentCollectionId = useSelector(currentCollectionIdSelector);
  const isQuestionnaire = useIsQuestionnaireFlow();

  const searchParams = new URLSearchParams(location.search);
  const queryCollectionId = searchParams.get('collectionId');

  const isSingularInvalid = !_.isEmpty(queryCollectionId) && queryCollectionId !== '0';
  const isPluralInvalid = _.isEmpty(queryCollectionId) || queryCollectionId === '0';

  const matchingCollectionTypeItem = _.find(collectionTypeItems, { collectionType: currentQuestion.collectionType });
  const readyToPerformCheck =
    !collectionTypeItemsLoading &&
    !pageLoading &&
    !pageUpdating &&
    // No coll id url params in questionnaire (kept in state instead)
    !isQuestionnaire &&
    !_.isNil(currentQuestion) &&
    !_.isNil(matchingCollectionTypeItem) &&
    !_.isNil(currentCollectionId) &&
    !_.includes(IGNORE_COLLECTION_TYPES, currentQuestion.collectionType) &&
    // Ignore non-plural state questions
    (currentQuestion.collectionType !== COLLECTION_TYPE__STATE_RETURN || isStateQuestionPlural(currentQuestion));

  const checkAndHandleInvalidCollectionId = useEvent(() => {
    if (isSingularInvalid && !matchingCollectionTypeItem.plural) {
      searchParams.delete('collectionId');
      history.replace({ pathname: location.pathname, search: searchParams.toString() });
    }

    if (isPluralInvalid && matchingCollectionTypeItem.plural) {
      defaultCaptureMessage('invalid collection id url param', { queryCollectionId, currentQuestion });
      history.replace(`${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
    }
  });

  useEffect(() => {
    if (readyToPerformCheck) {
      checkAndHandleInvalidCollectionId();
    }
  }, [currentQuestion, readyToPerformCheck, checkAndHandleInvalidCollectionId]);
};
