import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import PaymentElements from '@app/src/Components/Subscription/PaymentModal/PaymentElements';
import { setIsPaymentModalOpen } from '@app/src/actions/taxFlowActions';
import { useGetAccountDetailsQuery } from '@app/src/api/profileApi';
import CloseIcon from '@app/src/assets/close.svg?react';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import { updatingSelector, originSelector, trialLengthSelector } from '@app/src/selectors/pricingSelectors';
import '@app/src/Components/Subscription/PaymentModal/PaymentModal.scss';

const PaymentModal = ({ history, updating, origin, setIsPaymentModalOpen, trialLength }) => {
  const { data: accountDetails, isLoading: accountDetailsLoading } = useGetAccountDetailsQuery();

  if (accountDetailsLoading) {
    return null;
  }
  const onClose = () => {
    if (!updating) {
      setIsPaymentModalOpen(false);
    }
  };

  const { annualPrice } = accountDetails;

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle className='payment-modal-title'>
        {origin === 'web-paywall' ? 'Select' : 'Edit'} payment method
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          color: 'primary.main',
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className='payment-modal-body'>
        <Stack spacing={1}>
          {origin === 'web-paywall' && (
            <div className='payment-modal-offer'>
              {trialLength > 0 ? 'FREE first 7 days, then' : 'Restart your subscription for'}{' '}
              {currencyWith0DecimalPlaces(annualPrice)}/yr. Cancel any time.
            </div>
          )}
          <PaymentElements
            origin={origin}
            history={history}
            buttonCopy={origin === 'web-paywall' ? 'Subscribe' : 'Update'}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  updating: updatingSelector(state),
  origin: originSelector(state),
  trialLength: trialLengthSelector(state)
});

const mapDispatchToProps = {
  setIsPaymentModalOpen
};

const ConnectedPaymentModal = connect(mapStateToProps, mapDispatchToProps)(PaymentModal);

export default ConnectedPaymentModal;
