import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import TaxFlowPremiumSelfUnlockModal from '@app/src/Components/TaxFlow/Common/TaxFlowPremiumSelfUnlockModal';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import history from '@app/src/keeperHistory';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { moveToNotStarted } from '@app/src/services/taxFlowService';
import { advanceToCurrentSectionPage } from '@app/src/taxflow/main/services/mainService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';

const SettingsLinkedAccounts = ({
  selfUnlockModalOpen,
  setSelfUnlockModalOpen,
  advanceToCurrentSectionPage,
  moveToNotStarted
}) => {
  const { data: isPremiumSubscriber } = useIsPremiumSubscriber();
  useEffect(() => {
    trackActivity('navigation: connected accounts');
  }, []);

  return (
    <>
      <div className='question-title'>Linked Accounts</div>
      <Divider />
      <BankList origin='settings' type='settings' />
      {isPremiumSubscriber ? (
        <TaxFlowPremiumSelfUnlockModal />
      ) : (
        <TaxFlowConfirmModal
          open={selfUnlockModalOpen}
          setOpen={setSelfUnlockModalOpen}
          header='Edit your return and re-submit?'
          body="You'll need to re-submit for review after editing your return."
          // non breaking hyphen
          confirmText='Yes, edit and re&#8209;submit'
          onConfirm={async () => {
            trackActivity('self unlock: modal confirmed');
            await moveToNotStarted();
            await advanceToCurrentSectionPage({ history });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state)
});

const mapDispatchToProps = {
  setSelfUnlockModalOpen,
  advanceToCurrentSectionPage,
  moveToNotStarted
};

const ConnectedSettingsLinkedAccounts = connect(mapStateToProps, mapDispatchToProps)(SettingsLinkedAccounts);

export default ConnectedSettingsLinkedAccounts;
