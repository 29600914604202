// path components
export const HOME_PATH_COMPONENTS = {
  ACQUIRED: 'credit-home-acquired',
  ADDRESS: 'credit-home-address',
  DATES: 'credit-home-dates',
  DEPRECIATION: 'credit-home-depreciation',
  EXPENSES_MORTGAGE_INTEREST: 'credit-home-expenses-mortgage-interest',
  EXPENSES_RENT: 'credit-home-expenses-rent',
  EXPENSES_TOTAL: 'credit-home-expenses-total',
  NAV_START: 'credit-home-nav-start',
  OFFICE_SIZE: 'credit-home-office-size',
  OPTIONS: 'credit-home-options',
  PAST_DEPRECIATION: 'credit-home-past-depreciation',
  PRESELECT_ADDRESS: 'credit-home-preselect-address',
  PRIOR_DEPRECIATION: 'credit-home-prior-depreciation',
  TYPE: 'credit-home-type',
  VALUE: 'credit-home-value',
  YEARS_DEPRECIATION: 'credit-home-years-depreciation'
};

// slugs
export const HOME_SLUGS = {
  ACQUIRED: 'credit-home-acquired',
  ADDRESS: 'credit-home-address',
  ADDRESS_APT: 'credit-home-address-apt',
  ADDRESS_CITY: 'credit-home-address-city',
  ADDRESS_STATE: 'credit-home-address-state',
  ADDRESS_STREET: 'credit-home-address-street',
  ADDRESS_ZIP: 'credit-home-address-zip',
  DATES: 'credit-home-dates',
  DEPRECIATION: 'credit-home-depreciation',
  EXPENSES_MORTGAGE_INTEREST: 'credit-home-expenses-mortgage-interest',
  EXPENSES_RENT: 'credit-home-expenses-rent',
  EXPENSES_TOTAL: 'credit-home-expenses-total',
  NAV_START: 'credit-home-nav-start',
  OFFICE_SIZE: 'credit-home-office-size',
  OPTIONS: 'credit-home-options',
  PAST_DEPRECIATION: 'credit-home-past-depreciation',
  PRESELECT_ADDRESS: 'credit-home-preselect-address',
  PRIOR_DEPRECIATION: 'credit-home-prior-depreciation',
  TYPE: 'credit-home-type',
  VALUE: 'credit-home-value',
  YEARS_DEPRECIATION: 'credit-home-years-depreciation'
};

export const HOME_ENDPOINT_ATTRIBUTES = {
  ADDRESS_STATE: 'credit-home-address-state',
  ADDRESS_STREET: 'credit-home-address-street',
  DATES: 'credit-home-dates',
  OFFICE_SIZE_TOTAL: 'credit-home-office-size-total',
  OFFICE_SIZE_WORK_SPACE: 'credit-home-office-size-work-space',
  OPTIONS: 'credit-home-options',
  VALUE_IMPROVEMENTS: 'credit-home-value-improvements',
  VALUE_LAND: 'credit-home-value-land',
  VALUE_PURCHASE_PRICE: 'credit-home-value-purchase-price',
  YEARS_DEPRECIATION: 'credit-home-years-depreciation'
};

// collection types
export const COLLECTION_TYPE__HOME = 'home';
