export const INCOME_PATH_COMPONENTS = {
  DIV_INFO: 'income-div-info',
  FREELANCE_1099_MISC: 'income-freelance-1099-misc',
  FREELANCE_1099_NEC: 'income-freelance-1099-nec',
  FREELANCE_1099_TYPE: 'income-freelance-1099-type',
  FREELANCE_1099K_EXPENSES_INFO: 'income-freelance-1099k-expenses-info',
  FREELANCE_1099K_INFO: 'income-freelance-1099k-info',
  FREELANCE_AGI: 'income-freelance-agi',
  FREELANCE_BUSINESS_CODE_INFO: 'income-freelance-business-code-info',
  FREELANCE_JOB: 'income-freelance-job',
  INTEREST_INFO: 'income-interest-info',
  INVEST_INFO: 'income-invest-info',
  INVEST_MORE_INFO: 'income-invest-more-info',
  PERSONAL_ITEMS: 'income-personal-items',
  RETIREMENT_PENSION_INFO: 'income-retirement-pension-info',
  RETIREMENT_SSA_INFO: 'income-retirement-ssa-info',
  RETIREMENT_TYPE: 'income-retirement-type',
  UNEMPLOYMENT_INFO: 'income-unemployment-info',
  W2_INFO: 'income-w2-info',
  W2G_INFO: 'income-w2g-info'
};

export const INCOME_SLUGS = {
  DIV_ADDRESS_CITY: 'income-div-address-city',
  DIV_ADDRESS_STATE: 'income-div-address-state',
  DIV_ADDRESS_STREET: 'income-div-address-street',
  DIV_ADDRESS_ZIP: 'income-div-address-zip',
  DIV_CAPITAL_GAINS_FOREIGN_AMOUNT: 'income-div-capital-gains-foreign-amount',
  DIV_EARNER: 'income-div-earner',
  DIV_FED_TAX_WITHHELD: 'income-div-fed-tax-withheld',
  DIV_FOREIGN_TAX_PAID: 'income-div-foreign-tax-paid',
  DIV_INFO: 'income-div-info',
  DIV_ORDINARY_FOREIGN_AMOUNT: 'income-div-ordinary-foreign-amount',
  DIV_QUALIFIED_FOREIGN_AMOUNT: 'income-div-qualified-foreign-amount',
  DIV_STATE: 'income-div-state',
  DIV_STATE_ID: 'income-div-state-id',
  DIV_STATE_TAX_WITHHELD: 'income-div-state-tax-withheld',
  DIV_UNCOMMON: 'income-div-uncommon',
  FREELANCE_1099_MISC: 'income-freelance-1099-misc',
  FREELANCE_1099_NEC: 'income-freelance-1099-nec',
  FREELANCE_1099_TYPE: 'income-freelance-1099-type',
  FREELANCE_1099K_EXPENSES_AMOUNT: 'income-freelance-1099k-expenses-amount',
  FREELANCE_1099K_EXPENSES_DESCRIPTION: 'income-freelance-1099k-expenses-description',
  FREELANCE_1099K_EXPENSES_INFO: 'income-freelance-1099k-expenses-info',
  FREELANCE_1099K_INFO: 'income-freelance-1099k-info',
  FREELANCE_ADDRESS_CITY: 'income-freelance-address-city',
  FREELANCE_ADDRESS_STATE: 'income-freelance-address-state',
  FREELANCE_ADDRESS_STREET: 'income-freelance-address-street',
  FREELANCE_ADDRESS_ZIP: 'income-freelance-address-zip',
  FREELANCE_AGI: 'income-freelance-agi',
  FREELANCE_BOX_EIGHTEEN: 'income-freelance-box-18',
  FREELANCE_BOX_FIVE: 'income-freelance-box-5',
  FREELANCE_BOX_FOUR: 'income-freelance-box-4',
  FREELANCE_BOX_SIXTEEN: 'income-freelance-box-16',
  FREELANCE_BOX_SIX: 'income-freelance-box-6',
  FREELANCE_BOX_SEVENTEEN: 'income-freelance-box-17',
  FREELANCE_BUSINESS_CODE: 'income-freelance-business-code',
  FREELANCE_BUSINESS_CODE_INFO: 'income-freelance-business-code-info',
  FREELANCE_INDUSTRY: 'income-freelance-industry',
  FREELANCE_JOB: 'income-freelance-job',
  FREELANCE_JOB_NAME: 'income-freelance-job-name',
  FREELANCE_MISC_BOX_ONE: 'income-freelance-misc-box-1',
  FREELANCE_MISC_BOX_SEVEN: 'income-freelance-misc-box-7',
  FREELANCE_MISC_BOX_TWO: 'income-freelance-misc-box-2',
  FREELANCE_NEC_BOX_SEVEN: 'income-freelance-nec-box-7',
  FREELANCE_OTHER_FIELDS: 'income-freelance-other-fields',
  FREELANCE_PAYER_ID: 'income-freelance-payer-id',
  FREELANCE_PAYER_STATE_NUMBER: 'income-freelance-payer-state-number',
  FREELANCE_PAYERS_NAME: 'income-freelance-payers-name',
  FREELANCE_STATE: 'income-freelance-state',
  FREELANCE_STATE_INCOME: 'income-freelance-state-income',
  FREELANCE_STATE_TAX: 'income-freelance-state-tax',
  FREELANCE_WHO: 'income-freelance-who',
  INTEREST_ADDRESS_CITY: 'income-interest-address-city',
  INTEREST_ADDRESS_STATE: 'income-interest-address-state',
  INTEREST_ADDRESS_STREET: 'income-interest-address-street',
  INTEREST_ADDRESS_ZIP: 'income-interest-address-zip',
  INTEREST_EARNER: 'income-interest-earner',
  INTEREST_EARLY_PENALTY: 'income-interest-early-penalty',
  INTEREST_FED_TAX_WITHHELD: 'income-interest-fed-tax-withheld',
  INTEREST_FOREIGN_COUNTRY: 'income-interest-foreign-country',
  INTEREST_FOREIGN_TAX_PAID: 'income-interest-foreign-tax-paid',
  INTEREST_GOVERNMENT_INTEREST: 'income-interest-government-interest',
  INTEREST_INFO: 'income-interest-info',
  INTEREST_INVESTMENT_EXPENSES: 'income-interest-investment-expenses',
  INTEREST_STATE: 'income-interest-state',
  INTEREST_STATE_ID: 'income-interest-state-id',
  INTEREST_STATE_TAX_EXEMPT: 'income-interest-state-tax-exempt',
  INTEREST_STATE_TAX_WITHHELD: 'income-interest-state-tax-withheld',
  INTEREST_TAX_EXEMPT: 'income-interest-tax-exempt',
  INTEREST_UNCOMMON: 'income-interest-uncommon',
  INVEST_AMT_COST_BASIS: 'income-invest-amt-cost-basis',
  INVEST_CATEGORY: 'income-invest-category',
  INVEST_DATE_ACQUISITION: 'income-invest-date-acquisition',
  INVEST_DATE_SALE: 'income-invest-date-sale',
  INVEST_DESCRIPTION: 'income-invest-description',
  INVEST_EARNER: 'income-invest-earner',
  INVEST_FED_TAX_WITHHELD: 'income-invest-fed-tax-withheld',
  INVEST_FEDERAL_ID: 'income-invest-federal-id',
  INVEST_INFO: 'income-invest-info',
  INVEST_LONG_TERM_HEADER: 'income-invest-long-term-header',
  INVEST_MANUAL_UPLOAD: 'income-invest-manual-upload',
  INVEST_MARKET_DISCOUNT: 'income-invest-market-discount',
  INVEST_MORE_INFO: 'income-invest-more-info',
  INVEST_SHORT_TERM_COST_BASIS: 'income-invest-short-term-cost-basis',
  INVEST_SHORT_TERM_HEADER: 'income-invest-short-term-header',
  INVEST_SHORT_TERM_SALE_PROCEEDS: 'income-invest-short-term-sale-proceeds',
  INVEST_SHORT_TERM_WASH_SALE_LOSS: 'income-invest-short-term-wash-sale-loss',
  INVEST_STATE: 'income-invest-state',
  INVEST_STATE_ID: 'income-invest-state-id',
  INVEST_STATE_TAX_WITHHELD: 'income-invest-state-tax-withheld',
  INVEST_UNIFICATION: 'income-invest-unification',
  INVEST_UNKNOWN_TERM: 'income-invest-unknown-term',
  PERSONAL_ITEMS: 'income-personal-items',
  PERSONAL_ITEMS_ACQUIRED: 'income-personal-items-acquired',
  PERSONAL_ITEMS_MULTIPLE_DATES: 'income-personal-items-multiple-dates',
  PERSONAL_ITEMS_SOLD: 'income-personal-items-sold',
  PERSONAL_ITEMS_WHO: 'income-personal-items-who',
  RETIREMENT_PENSION_INFO: 'income-retirement-pension-info',
  RETIREMENT_PENSION_PAYER_ADDRESS_APT: 'income-retirement-pension-payer-address-apt',
  RETIREMENT_PENSION_PAYER_ADDRESS_CITY: 'income-retirement-pension-payer-address-city',
  RETIREMENT_PENSION_PAYER_ADDRESS_STATE: 'income-retirement-pension-payer-address-state',
  RETIREMENT_PENSION_PAYER_ADDRESS_STREET: 'income-retirement-pension-payer-address-street',
  RETIREMENT_PENSION_PAYER_ADDRESS_ZIP: 'income-retirement-pension-payer-address-zip',
  RETIREMENT_PENSION_ROTH: 'income-retirement-pension-roth',
  RETIREMENT_PENSION_WHO: 'income-retirement-pension-who',
  RETIREMENT_SSA_INFO: 'income-retirement-ssa-info',
  RETIREMENT_SSA_WHO: 'income-retirement-ssa-who',
  RETIREMENT_TYPE: 'income-retirement-type',
  UNEMPLOYMENT_ADDRESS_CITY: 'income-unemployment-address-city',
  UNEMPLOYMENT_ADDRESS_STATE: 'income-unemployment-address-state',
  UNEMPLOYMENT_ADDRESS_STREET: 'income-unemployment-address-street',
  UNEMPLOYMENT_ADDRESS_ZIP: 'income-unemployment-address-zip',
  UNEMPLOYMENT_AG_PAYMENTS: 'income-unemployment-ag-payments',
  UNEMPLOYMENT_CARRY_TO_FORM: 'income-unemployment-carry-to-form',
  UNEMPLOYMENT_EARNER: 'income-unemployment-earner',
  UNEMPLOYMENT_INFO: 'income-unemployment-info',
  UNEMPLOYMENT_LOCAL_PAYMENT: 'income-unemployment-local-payment',
  UNEMPLOYMENT_LOCAL_WITHHOLDING: 'income-unemployment-local-withholding',
  UNEMPLOYMENT_MARKET_GAIN: 'income-unemployment-market-gain',
  UNEMPLOYMENT_MULTI_FORM_NUM: 'income-unemployment-multi-form-num',
  UNEMPLOYMENT_RTAA_PAYMENT: 'income-unemployment-rtaa-payment',
  UNEMPLOYMENT_STATE: 'income-unemployment-state',
  UNEMPLOYMENT_STATE_ID: 'income-unemployment-state-id',
  UNEMPLOYMENT_STATE_LOCAL_REFUND: 'income-unemployment-state-local-refund',
  UNEMPLOYMENT_STATE_LOCAL_REFUND_YEAR: 'income-unemployment-state-local-refund-year',
  UNEMPLOYMENT_STATE_UNEMPLOYMENT: 'income-unemployment-state-unemployment',
  UNEMPLOYMENT_TAXABLE_GRANTS: 'income-unemployment-taxable-grants',
  UNEMPLOYMENT_TRADE_BUSINESS: 'income-unemployment-trade-business',
  UNEMPLOYMENT_UNCOMMON: 'income-unemployment-uncommon',
  W2_ADDRESS_COUNTRY: 'income-w2-address-country',
  W2_ADDRESS_FOREIGN_POSTAL_CODE: 'income-w2-address-foreign-postal-code',
  W2_ADDRESS_PROVINCE: 'income-w2-address-province',
  W2_ALLOCATED_TIPS: 'income-w2-allocated-tips',
  W2_DEPENDENT_CARE_BENEFITS: 'income-w2-dependent-care-benefits',
  W2_EMPLOYER_ADDRESS_APT: 'income-w2-employer-address-apt',
  W2_EMPLOYER_ADDRESS_CITY: 'income-w2-employer-address-city',
  W2_EMPLOYER_ADDRESS_STATE: 'income-w2-employer-address-state',
  W2_EMPLOYER_ADDRESS_STREET: 'income-w2-employer-address-street',
  W2_EMPLOYER_ADDRESS_ZIP: 'income-w2-employer-address-zip',
  W2_FOREIGN_ADDRESS: 'income-w2-employer-foreign-address',
  W2_FOURTEEN_A: 'income-w2-fourteen-a',
  W2_FOURTEEN_B: 'income-w2-fourteen-b',
  W2_FOURTEEN_C: 'income-w2-fourteen-c',
  W2_FOURTEEN_D: 'income-w2-fourteen-d',
  W2_HAS_EXTRA_STATE: 'income-w2-has-extra-state',
  W2_INFO: 'income-w2-info',
  W2_LOCAL_NAME: 'income-w2-local-name',
  W2_LOCAL_NAME_2: 'income-w2-local-name-2',
  W2_LOCAL_TAXES: 'income-w2-local-taxes',
  W2_LOCAL_TAXES_2: 'income-w2-local-taxes-2',
  W2_LOCAL_WAGES: 'income-w2-local-wages',
  W2_LOCAL_WAGES_2: 'income-w2-local-wages-2',
  W2_NONQUALIFIED_PLANS: 'income-w2-nonqualified-plans',
  W2_RETIREMENT_PLAN: 'income-w2-retirement-plan',
  W2_SICK_PAY: 'income-w2-sick-pay',
  W2_SOCIAL_SECURITY_TIPS: 'income-w2-social-security-tips',
  W2_STATE: 'income-w2-state',
  W2_STATE_2: 'income-w2-state-2',
  W2_STATE_EMPLOYER_ID: 'income-w2-state-employer-id',
  W2_STATE_EMPLOYER_ID_2: 'income-w2-state-employer-id-2',
  W2_STATE_INCOME_TAX: 'income-w2-state-income-tax',
  W2_STATE_INCOME_TAX_2: 'income-w2-state-income-tax-2',
  W2_STATE_WAGES: 'income-w2-state-wages',
  W2_STATE_WAGES_2: 'income-w2-state-wages-2',
  W2_STATUTORY_EMPLOYEE: 'income-w2-statutory-employee',
  W2_TWELVE_A: 'income-w2-twelve-a',
  W2_TWELVE_B: 'income-w2-twelve-b',
  W2_TWELVE_C: 'income-w2-twelve-c',
  W2_TWELVE_D: 'income-w2-twelve-d',
  W2_UNCOMMON: 'income-w2-uncommon',
  W2_WHO: 'income-w2-who',
  W2G_ADDRESS_CITY: 'income-w2g-address-city',
  W2G_ADDRESS_STATE: 'income-w2g-address-state',
  W2G_ADDRESS_STREET: 'income-w2g-address-street',
  W2G_ADDRESS_ZIP: 'income-w2g-address-zip',
  W2G_INFO: 'income-w2g-info',
  W2G_WHO: 'income-w2g-who'
};

export const INCOME_ENDPOINT_ATTRIBUTES = {
  DIV_ORDINARY: 'income-div-ordinary',
  DIV_PAYER_NAME: 'income-div-payer-name',
  FREELANCE_BUSINESS_CODE: 'income-freelance-business-code',
  FREELANCE_INCOME: 'income-freelance-income',
  FREELANCE_INDUSTRY: 'income-freelance-industry',
  FREELANCE_JOB_NAME: 'income-freelance-job-name',
  FREELANCE_MISC_BOX_2: 'income-freelance-misc-box-2',
  FREELANCE_STARTED: 'income-freelance-started',
  FREELANCE_WHO: 'income-freelance-who',
  G1099_COMPENSATION: 'income-1099g-compensation',
  G1099_PAYER_NAME: 'income-1099g-payer-name',
  INTEREST_AMOUNT: 'income-interest-amount',
  INTEREST_GOVERNMENT_INTEREST: 'income-interest-government-interest',
  INTEREST_PAYER_NAME: 'income-interest-payer-name',
  INVEST_DESCRIPTION: 'income-invest-description',
  INVEST_LONG_TERM_COST_BASIS: 'income-invest-long-term-cost-basis',
  INVEST_LONG_TERM_SALE_PROCEEDS: 'income-invest-long-term-sale-proceeds',
  INVEST_SALE_PROCEEDS: 'income-invest-sale-proceeds',
  INVEST_SHORT_TERM_COST_BASIS: 'income-invest-short-term-cost-basis',
  INVEST_SHORT_TERM_SALE_PROCEEDS: 'income-invest-short-term-sale-proceeds',
  INVEST_STARTED: 'income-invest-started',
  INVEST_UNKNOWN_TERM_COST_BASIS: 'income-invest-unknown-term-cost-basis',
  INVEST_UNKNOWN_TERM_SALE_PROCEEDS: 'income-invest-unknown-term-sale-proceeds',
  PERSONAL_ITEMS_AMOUNT: 'income-personal-items-amount',
  PERSONAL_ITEMS_DESCRIPTION: 'income-personal-items-description',
  RETIREMENT_PENSION_GROSS: 'income-retirement-pension-gross-distribution',
  RETIREMENT_SSA_NET: 'income-retirement-ssa-net-benefits',
  W2_EMPLOYER: 'income-w2-employer',
  W2_WAGES: 'income-w2-wages',
  W2G_PAYER_NAME: 'income-w2g-payer-name',
  W2G_REPORTABLE_WINNINGS: 'income-w2g-reportable-winnings'
};

export const INCOME_COLLECTION_TYPES = {
  COMPOSITE: 'income-composite',
  DIV: 'income-div',
  FREELANCE: 'income-freelance',
  G1099: 'income-1099G',
  INTEREST: 'income-interest',
  INVEST: 'income-invest',
  PERSONAL_ITEMS: 'income-personal-items',
  RETIREMENT: 'income-retirement',
  W2: 'income-w2',
  W2G: 'income-w2g'
};

// 1099B groupings
export const INCOME_INVEST_INTRO_SLUGS = [
  'income-invest-earner',
  'income-invest-federal-id',
  'income-invest-description'
];
export const INCOME_INVEST_SHORT_TERM_SLUGS = [
  'income-invest-short-term-header',
  'income-invest-short-term-sale-proceeds',
  'income-invest-short-term-cost-basis',
  'income-invest-short-term-wash-sale-loss'
];
export const INCOME_INVEST_LONG_TERM_SLUGS = [
  'income-invest-long-term-header',
  'income-invest-long-term-sale-proceeds',
  'income-invest-long-term-cost-basis',
  'income-invest-long-term-wash-sale-loss'
];
export const INCOME_INVEST_UNKNOWN_TERM_SLUGS = [
  'income-invest-unknown-term-sale-proceeds',
  'income-invest-unknown-term-cost-basis',
  'income-invest-unknown-term-wash-sale-loss',
  'income-invest-date-acquisition',
  'income-invest-date-sale',
  'income-invest-market-discount',
  'income-invest-state',
  'income-invest-state-id',
  'income-invest-state-tax-withheld',
  'income-invest-fed-tax-withheld',
  'income-invest-amt-cost-basis'
];

/** Address suffixes */
export const ADDRESS_SLUG_SUFFIXES = [
  '-address-street',
  '-address-apt',
  '-address-city',
  '-address-state',
  '-address-zip'
];
