import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import SubscriptionFlow from '@app/src/Components/Subscription/SubscriptionFlow';
import { SUBSCRIPTION_MODAL_TYPES } from '@app/src/constants/constants';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import { originSelector } from '@app/src/selectors/pricingSelectors';
import { subscriptionModalShowSelector } from '@app/src/selectors/taxFlowSelectors';
import '@app/src/taxflow/navigation/components/TaxFlowSubscriptionModal.scss';

const TaxFlowSubscriptionModal = () => {
  const open = useSelector(subscriptionModalShowSelector);

  const origin = useSelector(originSelector);

  const initialScreen =
    origin === PRICING_ORIGINS.TAX_FILING_TRIAL_PAYWALL
      ? SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_BENEFITS
      : SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_TYPE;

  return (
    <Dialog
      slotProps={{ root: { sx: { zIndex: 1000 } } }}
      sx={{
        '.MuiPaper-root': {
          padding: '16px',
          width: 'fit-content',
          borderRadius: '8px'
        }
      }}
      className='taxflow-subscription-modal'
      open={open}
    >
      <SubscriptionFlow initialScreen={initialScreen} />
    </Dialog>
  );
};

export default TaxFlowSubscriptionModal;
