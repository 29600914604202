import React from 'react';
import TaxEstimateDetails from '@app/src/Components/TaxProfile/TaxEstimateDetails';
import useTaxProfile from '@app/src/Components/TaxProfile/hooks/useTaxProfile';

const TaxProfile = () => {
  const { detailsProps, isLoading } = useTaxProfile();

  if (isLoading) {
    return <TaxEstimateDetails isLoading />;
  }
  return <TaxEstimateDetails {...detailsProps} />;
};

export default TaxProfile;
