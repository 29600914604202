import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import '@app/src/Components/Bank/LinkedAccount/LinkedAccount.scss';

const LinkedAccountLoader = () => (
  <div className='linked-account'>
    <div className='linked-account-logo-container'>
      <CircularProgress size={30} color='success' />
    </div>
    <div className='linked-account-heading'>
      <span className='linked-account-name'>Bank linking...</span>
    </div>
  </div>
);

export default LinkedAccountLoader;
