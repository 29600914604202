import React from 'react';
import { CloseCircle, TickCircle } from 'iconsax-react';
import ReactMarkdown from 'react-markdown';
import { colorError, colorSuccess } from '@app/src/theme';
import '@app/src/Components/Subscription/Common/SubscriptionFeature.scss';

const SubscriptionFeature = ({ text, included, style }) => (
  <div className='subscription-feature' style={style}>
    <div className='icon'>
      {included ? (
        <TickCircle variant='Bulk' color={colorSuccess} />
      ) : (
        <CloseCircle variant='Bulk' color={colorError} />
      )}
    </div>
    <ReactMarkdown
      components={{
        h4: 'p'
      }}
    >
      {text}
    </ReactMarkdown>
  </div>
);

export default SubscriptionFeature;
