import _ from 'lodash';
import moment from 'moment';
import QueryString from 'qs';
import {
  clearError,
  setHolisticOnboardingContentfulValues,
  setHolisticOnboardingDependents,
  setHolisticOnboardingDrive,
  setHolisticOnboardingEmail,
  setHolisticOnboardingError,
  setHolisticOnboardingFirstName,
  setHolisticOnboardingHome,
  setHolisticOnboardingIncomeType,
  setHolisticOnboardingInitialLoad,
  setHolisticOnboardingJob,
  setHolisticOnboardingLastName,
  setHolisticOnboardingLoading,
  setHolisticOnboardingMarried,
  setHolisticOnboardingMeal,
  setHolisticOnboardingPhone,
  setHolisticOnboardingRent,
  setHolisticOnboardingStartDate,
  setHolisticOnboardingTravel
} from '@app/src/actions/holisticOnboardingActions';
import { setOnboardingExperiments, setOnboardingMarketingContent } from '@app/src/actions/onboardingActions';
import {
  ONBOARDING_EXPERIMENTS_KEY,
  PATHNAME_ONBOARDING__APP_DOWNLOAD,
  PATHNAME_ONBOARDING__DRIVE,
  PATHNAME_ONBOARDING__HOME,
  PATHNAME_ONBOARDING__JOB_DURATION,
  PATHNAME_ONBOARDING__JOB_SELECT,
  PATHNAME_ONBOARDING__LINK,
  PATHNAME_ONBOARDING__MEAL,
  PATHNAME_ONBOARDING__PHONE,
  PATHNAME_ONBOARDING__SIGNUP,
  PATHNAME_ONBOARDING__SITUATIONS,
  PATHNAME_ONBOARDING__TRAVEL,
  PATHNAME_ONBOARDING__VERIFY
} from '@app/src/constants/onboardingConstants';
import { CONTENTFUL_CONTENT_TYPES } from '@app/src/global/Environment';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import {
  isAuthenticatedSelector,
  isEmailUniqueSelector,
  isPhoneUniqueSelector
} from '@app/src/selectors/authSelectors';
import { bankLinkPendingSelector, bankListSelector } from '@app/src/selectors/bankSelectors';
import {
  contentSelector,
  dependentsSelector,
  driveSelector,
  emailSelector,
  firstNameSelector,
  homeOwnerSelector,
  homeSelector,
  incomeTypeSelector,
  jobDurationSelector,
  jobsSelector,
  lastNameSelector,
  marriedSelector,
  mealSelector,
  phoneSelector,
  rentSelector,
  studentLoansSelector,
  travelSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { generateClientDedupId, setUserWithObj, trackActivity } from '@app/src/services/analyticsService';
import {
  axiosWithErrorHandling,
  checkEmailUnique,
  checkPhoneUnique,
  requestLoginEmail,
  requestLoginOtp,
  setTokenUser
} from '@app/src/services/authService';
import { getContentfulEntries, getJobCategoryList } from '@app/src/services/contentfulService';
import { sendOnboardingUtms, setAppAnalyticsLocalStorage } from '@app/src/services/onboardingAnalyticsService';
import { initOnboardingExperimentGroups } from '@app/src/services/onboardingExperimentService';
import { createReferrerCookies } from '@app/src/services/onboardingReferrerService';
import {
  clearOnboardingState,
  consolidateContentfulValues,
  getExperimentProperties,
  getLocalStorage,
  getMarketingData,
  preserveProgress
} from '@app/src/utils/holisticOnboardingUtils';

const getOnboardingMarketingContent = () => async (dispatch) => {
  const vals = await getContentfulEntries(CONTENTFUL_CONTENT_TYPES.MARKETING_CONTENT);

  const formattedContentVals = vals.items.reduce(
    (acc, { fields }) => ({ ...acc, [fields.contentName]: fields.content }),
    {}
  );

  dispatch(setOnboardingMarketingContent(formattedContentVals));
};

const getOnboardingPrerequisites = () => async (dispatch) => {
  await dispatch(getOnboardingMarketingContent());

  const experiments = getLocalStorage(ONBOARDING_EXPERIMENTS_KEY);

  if (experiments) {
    dispatch(setOnboardingExperiments(JSON.parse(experiments)));
  }
};

const initOnboardingParams =
  ({ params }) =>
  async (dispatch) => {
    await Promise.all([
      dispatch(getOnboardingPrerequisites()),
      dispatch(createReferrerCookies({ params })),
      dispatch(setAppAnalyticsLocalStorage({ params })),
      dispatch(sendOnboardingUtms({ params }))
    ]);
  };

export const initOnboarding =
  ({ history, location }) =>
  async (dispatch) => {
    try {
      dispatch(setHolisticOnboardingLoading(true));

      const params = QueryString.parse(location.search, { ignoreQueryPrefix: true });

      await dispatch(initOnboardingExperimentGroups({ history, params }));
      await dispatch(initOnboardingParams({ params }));

      const [contentful] = await Promise.all([getContentfulEntries('onboarding'), dispatch(getJobCategoryList())]);

      const formattedValues = consolidateContentfulValues(contentful);

      dispatch(setHolisticOnboardingContentfulValues(formattedValues));

      const localStorageValues = JSON.parse(getLocalStorage('onboardingState'));

      const {
        firstname = '',
        lastname = '',
        email = params?.email || '',
        phone = params?.phone || '',
        married = false,
        dependents = [],
        incomeType = [],
        drive = null,
        home = null,
        meal = null,
        travel = null,
        rent = '',
        jobs = [],
        jobDuration = ''
      } = localStorageValues || {};

      dispatch(setHolisticOnboardingFirstName(firstname));
      dispatch(setHolisticOnboardingLastName(lastname));
      dispatch(setHolisticOnboardingEmail(email));
      dispatch(setHolisticOnboardingPhone(phone));
      dispatch(setHolisticOnboardingMarried(married));
      dispatch(setHolisticOnboardingDependents(dependents));
      dispatch(setHolisticOnboardingIncomeType(incomeType));
      dispatch(setHolisticOnboardingDrive(drive));
      dispatch(setHolisticOnboardingHome(home));
      dispatch(setHolisticOnboardingMeal(meal));
      dispatch(setHolisticOnboardingTravel(travel));
      dispatch(setHolisticOnboardingRent(rent));
      dispatch(setHolisticOnboardingJob(jobs));
      dispatch(setHolisticOnboardingStartDate(jobDuration));

      if (isReactNative()) {
        sentMsgToReactNative('onboarding_initialized');
      }
    } catch ({ message }) {
      throw new Error({ message });
    } finally {
      dispatch(setHolisticOnboardingLoading(false));
      dispatch(setHolisticOnboardingInitialLoad(false));
    }
  };

export const initPage =
  ({ pathname }) =>
  async (dispatch, getState) => {
    const content = contentSelector(getState());

    const analyticsEvent = _.get(content, [pathname, 'analyticsEvent']);
    const title = _.get(content, [pathname, 'title']);

    trackActivity('question: view', {
      flow: 'onboarding',
      type: isReactNative() ? 'mobile webview' : 'web',
      is_authenticated: isAuthenticatedSelector(getState()),
      question: analyticsEvent,
      title,
      client_dedup_id: generateClientDedupId()
    });
  };

export const onContinue =
  ({ pathname, push }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setHolisticOnboardingLoading(true));

      const content = contentSelector(getState());
      const nextUrl = _.get(content, [pathname, 'nextUrl']);

      const baseAnalytics = {
        question: _.get(content, [pathname, 'analyticsEvent']),
        title: _.get(content, [pathname, 'title']),
        isAuthenticated: isAuthenticatedSelector(getState())
      };

      if (pathname === PATHNAME_ONBOARDING__SIGNUP) {
        const firstname = firstNameSelector(getState());
        const lastname = lastNameSelector(getState());
        const email = emailSelector(getState());

        await dispatch(checkEmailUnique(email));

        if (!isEmailUniqueSelector(getState())) {
          dispatch(requestLoginEmail({ email }, () => push(PATHNAME_ONBOARDING__VERIFY)));
          return;
        }

        const response = { firstname, lastname, email };

        preserveProgress(response, { ...baseAnalytics, answer: response });
      } else if (pathname === PATHNAME_ONBOARDING__PHONE) {
        const phone = phoneSelector(getState());
        await dispatch(checkPhoneUnique(`1${phone}`));

        const isPhoneUnique = isPhoneUniqueSelector(getState());

        if (isPhoneUnique) {
          await dispatch(createAccount());
        } else {
          dispatch(requestLoginOtp({ phone }, () => push(PATHNAME_ONBOARDING__VERIFY)));
          return;
        }

        const response = { phone };

        preserveProgress(response, { ...baseAnalytics, answer: response });
      } else if (pathname === PATHNAME_ONBOARDING__SITUATIONS) {
        const incomeType = incomeTypeSelector(getState());
        const married = marriedSelector(getState());
        const dependents = dependentsSelector(getState());
        const rent = rentSelector(getState());
        const studentLoans = studentLoansSelector(getState());
        const homeOwner = homeOwnerSelector(getState());

        const response = {
          incomeType,
          married,
          dependents,
          rent,
          studentLoans,
          homeOwner
        };

        window.analytics.identify({
          income_type: incomeType,
          married: married === 'married',
          dependents,
          onboarding_situations: {
            rent,
            studentLoans,
            homeOwner
          }
        });

        preserveProgress(response, { ...baseAnalytics, answer: incomeType });

        if (incomeType.includes('salaried') && incomeType.length === 1) {
          push(PATHNAME_ONBOARDING__SIGNUP);
          return;
        }
      } else if (pathname === PATHNAME_ONBOARDING__JOB_SELECT) {
        const response = { jobs: jobsSelector(getState()) };
        const formattedJobs = response.jobs.map(({ slug, name }) => slug ?? name);

        preserveProgress(response, { ...baseAnalytics, answer: formattedJobs });
      } else if (pathname === PATHNAME_ONBOARDING__JOB_DURATION) {
        const response = { jobDuration: jobDurationSelector(getState()) };

        const [year, month] = response.jobDuration.split(' - ');
        const formattedMonth = moment().month(month).format('M');

        const answer = `${year} - ${formattedMonth}`;

        preserveProgress(response, { ...baseAnalytics, answer });
      } else if (pathname === PATHNAME_ONBOARDING__DRIVE) {
        const response = { drive: driveSelector(getState()) };

        preserveProgress(response, { ...baseAnalytics, answer: response });
      } else if (pathname === PATHNAME_ONBOARDING__HOME) {
        const response = { home: homeSelector(getState()) };

        preserveProgress(response, { ...baseAnalytics, answer: response });
      } else if (pathname === PATHNAME_ONBOARDING__MEAL) {
        const response = { meal: mealSelector(getState()) };

        preserveProgress(response, { ...baseAnalytics, answer: response });
      } else if (pathname === PATHNAME_ONBOARDING__TRAVEL) {
        const response = { travel: travelSelector(getState()) };

        preserveProgress(response, { ...baseAnalytics, answer: response });
      } else if (pathname === PATHNAME_ONBOARDING__LINK) {
        const bankLinks = bankListSelector(getState());
        const response = { bankLinks };

        preserveProgress(response, { ...baseAnalytics, answer: response });

        // remove onboarding state from local storage after completion
        clearOnboardingState();

        if (isReactNative()) {
          sentMsgToReactNative('onboarding_completed');
        }

        if (bankLinks.length === 0 && !bankLinkPendingSelector(getState())) {
          push(PATHNAME_ONBOARDING__APP_DOWNLOAD);
          return;
        }
      }

      dispatch(clearError());
      push(nextUrl);
    } catch (error) {
      dispatch(setHolisticOnboardingError(error));
    } finally {
      dispatch(setHolisticOnboardingLoading(false));
    }
  };

const createAccount = () => async (dispatch, getState) => {
  try {
    const localStorageValues = JSON.parse(getLocalStorage('onboardingState'));

    const phone = '1' + phoneSelector(getState());
    const firstname = _.get(localStorageValues, 'firstname', firstNameSelector(getState()));
    const lastname = _.get(localStorageValues, 'lastname', lastNameSelector(getState()));
    const email = _.get(localStorageValues, 'email', emailSelector(getState()));
    const married = _.get(localStorageValues, 'married', marriedSelector(getState()));
    const dependents = _.get(localStorageValues, 'dependents', dependentsSelector(getState()));
    const incomeType = _.get(localStorageValues, 'incomeType', incomeTypeSelector(getState()));
    const car = _.get(localStorageValues, 'drive', driveSelector(getState()));
    const home = _.get(localStorageValues, 'home', homeSelector(getState()));
    const meal = _.get(localStorageValues, 'meal', mealSelector(getState()));
    const travel = _.get(localStorageValues, 'travel', travelSelector(getState()));
    const dates = _.get(localStorageValues, 'jobDuration', jobDurationSelector(getState()));
    const jobs = _.get(localStorageValues, 'jobs', jobsSelector(getState()));

    let formattedJobDates;

    if (dates) {
      const [year, month] = dates.split(' - ');
      const formattedMonth = moment().month(month).format('MM');

      formattedJobDates = jobs.map((job) => ({ job: job.slug ?? job.name, dates: { year, month: formattedMonth } }));
    }

    // Strip out frontend-only metadata from jobs (e.g. iconUrl, removable)
    const jobsWithMetadata = jobs.map(({ id, slug, name }) => ({
      id,
      slug,
      name
    }));

    const signupObject = {
      phone,
      firstname,
      lastname,
      email,
      married,
      car,
      home,
      meal,
      travel,
      dependents,
      income_type: incomeType,
      ...(formattedJobDates && { job_dates: formattedJobDates }),
      jobsWithMetadata
    };

    const marketingData = getMarketingData();
    const experimentProperties = getExperimentProperties(_.get(getState(), ['onboarding', ONBOARDING_EXPERIMENTS_KEY]));

    const data = {
      ...signupObject,
      ...marketingData,
      experiments: experimentProperties
    };

    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: `api/auth/signup`,
        data
      })
    );

    if (res.data.status === 'error') {
      throw new Error(res.data.message);
    }

    const token = _.get(res, ['data', 'data', 'token']);

    if (isReactNative()) {
      sentMsgToReactNative('onboarding_signup_success', { token });
    }

    await dispatch(setTokenUser(res.data));

    const user = userSelector(getState());

    trackActivity('signup (frontend)', {
      userId: user.id,
      phone: user.phone,
      referrer: marketingData.referrer,
      client_dedup_id: marketingData.client_dedup_id
    });

    const userObj = {
      ...signupObject,
      ...experimentProperties,
      Affiliate_referral: marketingData.referrer,
      // For analytics, we want to use the slug if it exists, otherwise the name
      // for backwards compatibility with Amplitude charts that expect an array of strings
      // And Amplitude doesn't support reading arrays of objects like `jobsWithMetadata`
      jobs: jobs.map(({ slug, name }) => slug ?? name),
      jobsWithMetadata: null
    };

    setUserWithObj(data.phone, userObj);
  } catch ({ message }) {
    trackActivity('onboarding: signup failed', { reason: message });
    dispatch(setHolisticOnboardingError(message));
  }
};
