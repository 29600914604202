import React, { useEffect, useMemo } from 'react';
import url from 'url';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import { Gift } from 'iconsax-react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import StreamAssistant from '@app/src/Components/Assistant/StreamAssistant';
import YearDropdown from '@app/src/Components/Common/YearDropdown/YearDropdown';
import { setExpensesYear } from '@app/src/actions/expenseReviewActions';
import { useUpdateYearPreferenceMutation } from '@app/src/api/profileApi';
import BankIcon from '@app/src/assets/bank-white.svg?react';
import IconClose from '@app/src/assets/close-white.svg?react';
import DepositedIcon from '@app/src/assets/dollar-circle-white.svg?react';
import IconLogo from '@app/src/assets/logo-white.svg?react';
import ExitIcon from '@app/src/assets/logout-white.svg?react';
import FileOnComputerIcon from '@app/src/assets/monitor-white.svg?react';
import SettingsIcon from '@app/src/assets/settings-white.svg?react';
import { Url_LOGIN } from '@app/src/constants/constants';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import { getIsTaxFilingSandwiched, isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import useYears from '@app/src/hooks/useYears';
import history from '@app/src/keeperHistory';
import { isImpersonatorSelector } from '@app/src/selectors/authSelectors';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import {
  isNavigationPanelEnabledSelector,
  isNavigationPanelOpenSelector
} from '@app/src/selectors/navigationListSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { referralRevampEnabledSelector } from '@app/src/selectors/workSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { logout } from '@app/src/services/authService';
import { sendDesktopLink } from '@app/src/services/taxFlowService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { advanceToCurrentSectionPage } from '@app/src/taxflow/main/services/mainService';
import { setIsNavigationPanelOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__PAST_RETURNS,
  PATH_COMPONENT__SWITCH_TO_DESKTOP,
  TAXFLOW_BASE_URL
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { colorPrimary } from '@app/src/theme';
import '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel.scss';

const TaxFlowNavigationPanel = ({
  user,
  currentQuestion,
  isNavigationPanelEnabled,
  isNavigationPanelOpen,
  setIsNavigationPanelOpen,
  setExpensesYear,
  expensesYear: preferredYear,
  logout,
  sendDesktopLink,
  isImpersonator
}) => {
  const { pathname } = useLocation();
  const { width: windowWidth } = useWindowSize();
  const year = useSelector(yearSelector);

  const isExpenseReview = pathname === '/expenses';
  const isTaxHome = pathname === `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`;
  const isMobileApp = isReactNative();
  const isDesktop = windowWidth >= 1024;
  const isFilingSandwiched = getIsTaxFilingSandwiched();

  const title = user && user.firstname ? `Welcome, ${user.firstname}!` : `My ${year} Taxes`;

  const { years, year: expensesYear } = useYearDropdownYears({
    year: preferredYear
  });

  const isSubsectionHighlighted = (sub) => {
    if (pathname.includes('/expenses')) {
      return expensesYear === sub.title;
    } else {
      return pathname === sub.path;
    }
  };

  const closeNavPanel = () => {
    setIsNavigationPanelOpen(false);
  };

  const onHomeClick = async () => {
    setIsNavigationPanelOpen(false);
    if (REACT_APP_ENV === 'staging-prod-db') {
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`,
          query: { impersonation: true }
        })
      );
    } else {
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
    }
  };

  const onWriteOffsClick = () => {
    setIsNavigationPanelOpen(false);
    history.push('/expenses');
  };

  const onSettingsClick = () => {
    setIsNavigationPanelOpen(false);
    history.push('/settings/linked-accounts');
  };

  const onFileOnComputerClick = () => {
    trackActivity('navigation panel: switch to desktop');
    setIsNavigationPanelOpen(false);
    sendDesktopLink();
    history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SWITCH_TO_DESKTOP}`);
  };

  const onReferralsClick = () => {
    setIsNavigationPanelOpen(false);
    history.push('/referrals');
  };

  const onExitClick = () => {
    if (isMobileApp) {
      trackActivity('navigation panel: exit to app', {
        origin: _.get(currentQuestion, ['slug'])
      });
      sentMsgToReactNative('taxes_home');
    } else {
      trackActivity('navigation panel: log out button clicked', {
        origin: _.get(currentQuestion, ['slug'])
      });
      logout();
      history.push(`/${TAXFLOW_BASE_URL}/${Url_LOGIN}`);
    }
  };

  const [updateYearPreference] = useUpdateYearPreferenceMutation();

  if ((isMobileApp && !isNavigationPanelEnabled && !isExpenseReview && !isTaxHome) || isFilingSandwiched) {
    return null;
  }

  return (
    <>
      {isNavigationPanelOpen && <div className='taxflow-navigation-panel-background' onClick={closeNavPanel} />}
      <Paper
        sx={{ zIndex: 1200, backgroundColor: colorPrimary, position: 'fixed' }}
        square
        elevation={2}
        className={classNames('taxflow-navigation-panel', { open: isNavigationPanelOpen })}
      >
        {(!isDesktop || isMobileApp) && (
          <div className='taxflow-navigation-panel-close'>
            <IconClose onClick={closeNavPanel} />
          </div>
        )}
        <IconLogo className='taxflow-navigation-panel-logo' />
        <div className='taxflow-navigation-panel-header-title'>{title}</div>
        <div className='taxflow-navigation-panel-list'>
          <YearDropdown
            years={years}
            value={expensesYear}
            onChange={async (event) => {
              setExpensesYear(event.target.value);
              setIsNavigationPanelOpen(false);
              await updateYearPreference({ year: event.target.value });
            }}
          />
          {[
            {
              sectionTitle: 'Deductions',
              basePath: '/expenses',
              renderCondition: !isMobileApp,
              renderIcon: () => <DepositedIcon />,
              onSectionClick: () => onWriteOffsClick(),
              key: 'deductions'
            },
            {
              sectionTitle: 'Tax filing',
              basePath: `/${TAXFLOW_BASE_URL}`,
              subSections: [
                {
                  title: expensesYear || 'File taxes',
                  path: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`
                },
                { title: 'Past returns', path: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__PAST_RETURNS}` }
              ],
              renderCondition: true,
              renderIcon: () => <BankIcon />,
              onSectionClick: () => onHomeClick(),
              onSubsectionClick: () => {
                setIsNavigationPanelOpen(false);
              },
              key: 'tax-filing'
            },
            {
              sectionTitle: 'Referrals',
              basePath: `/referrals`,
              renderCondition: !isMobileApp,
              renderIcon: () => <Gift />,
              onSectionClick: () => onReferralsClick(),
              key: 'referrals'
            },
            {
              sectionTitle: 'Settings',
              basePath: '/settings',
              subSections: [
                { title: 'Linked accounts', path: `/settings/linked-accounts` },
                { title: 'Notifications', path: `/settings/notifications` },
                { title: 'Login credentials', path: `/settings/login-credentials` },
                { title: 'Subscription', path: `/settings/subscription` }
              ],
              renderCondition: !isMobileApp,
              renderIcon: () => <SettingsIcon />,
              onSectionClick: () => onSettingsClick(),
              onSubsectionClick: () => {
                setIsNavigationPanelOpen(false);
              },
              key: 'settings'
            }
          ].map((s) => {
            if (!s.renderCondition) {
              return null;
            }

            const isOpen = pathname.includes(s.basePath);

            return (
              <div key={s.key} className='taxflow-navigation-panel-list-sub-item'>
                <div
                  className={classNames('taxflow-navigation-panel-list-item', {
                    'taxflow-navigation-panel-list-item-open': isOpen
                  })}
                  onClick={s.onSectionClick}
                  role='button'
                >
                  {s.renderIcon()}
                  <span>{s.sectionTitle}</span>
                </div>
                {isOpen ? (
                  <>
                    {s.subSections?.map((sub, idx) => (
                      <div
                        role='button'
                        onClick={() => {
                          s.onSubsectionClick(sub);
                          history.push(sub.path);
                        }}
                        key={`${s.key}-${idx}`}
                        className={classNames('taxflow-navigation-panel-list-item-subsection', {
                          'taxflow-navigation-panel-list-item-subsection-last': idx === s.subSections.length - 1,
                          'taxflow-navigation-panel-list-item-subsection-selected': isSubsectionHighlighted(sub)
                        })}
                      >
                        {sub.title}
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            );
          })}
          {isMobileApp && (
            <div className='taxflow-navigation-panel-list-item' onClick={onFileOnComputerClick} role='button'>
              <FileOnComputerIcon />
              <span>File on your computer</span>
            </div>
          )}
          <div className='taxflow-navigation-panel-list-item' onClick={onExitClick} role='button'>
            <ExitIcon />
            <span>{isMobileApp ? 'Exit tax filing' : 'Log out'}</span>
          </div>
        </div>
      </Paper>
      {/* Hide web ask when in-app (users can use the native ask tab) */}
      {!isImpersonator && !isReactNative() && <StreamAssistant />}
    </>
  );
};

const useYear = ({ years, originalYear }) => {
  const year = useMemo(() => {
    if (_.isEmpty(years)) {
      return '';
    }

    if (originalYear.toString() > Math.max(...years).toString()) {
      return Math.max(...years).toString();
    }

    if (originalYear.toString() < Math.min(...years)) {
      return Math.min(...years).toString();
    }

    return originalYear;
  }, [originalYear, years]);

  return year;
};

const useYearDropdownYears = ({ year: originalYear }) => {
  const [updateYearPreference] = useUpdateYearPreferenceMutation();

  const years = useYears();

  const year = useYear({ years, originalYear });

  useEffect(() => {
    if (year === originalYear || year === '') {
      return;
    }

    updateYearPreference({ year });
  }, [originalYear, updateYearPreference, year]);

  return {
    years,
    year
  };
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  currentQuestion: currentQuestionSelector(state),
  isNavigationPanelOpen: isNavigationPanelOpenSelector(state),
  isNavigationPanelEnabled: isNavigationPanelEnabledSelector(state),
  expensesYear: expensesYearSelector(state),
  isReferralRevampEnabled: referralRevampEnabledSelector(state),
  isImpersonator: isImpersonatorSelector(state)
});

const mapDispatchToProps = {
  advanceToCurrentSectionPage,
  setIsNavigationPanelOpen,
  setExpensesYear,
  logout,
  sendDesktopLink
};

const ConnectedTaxFlowNavigationPanel = connect(mapStateToProps, mapDispatchToProps)(TaxFlowNavigationPanel);

export default ConnectedTaxFlowNavigationPanel;
