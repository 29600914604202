import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { usePlaidLink } from 'react-plaid-link';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import { trackActivity } from '@app/src/services/analyticsService';
import { runOnSuccess, runOnExit, runOnEvent } from '@app/src/services/bankLinkService';

const OauthRedirect = ({
  linkToken,
  history,
  runOnSuccess,
  runOnExit,
  runOnEvent,
  defaultRoute,
  successRoute,
  getSuccessParams,
  loadOnSuccess
}) => {
  const [succeeded, setSucceeded] = useState(false);
  const [exited, setExited] = useState(false);

  const onSuccess = async (publicToken, metadata) => {
    setSucceeded(true);

    await runOnSuccess({ publicToken, metadata });

    const route = successRoute || defaultRoute;
    trackActivity('bank link: oauth redirect', {
      routeCalled: route
    });
    if (getSuccessParams) {
      history.push(`${route}?${getSuccessParams({ metadata })}`);
    } else {
      history.push(route);
    }
  };

  const onExit = async (error, metadata) => {
    await runOnExit({ error, metadata });

    if (!loadOnSuccess || (loadOnSuccess && !succeeded)) {
      history.push(defaultRoute);
    }

    setExited(true);
  };

  const onEvent = async (eventName, metadata) => {
    await runOnEvent({ eventName, metadata });
  };

  const config = {
    token: linkToken,
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit,
    onEvent
  };

  trackActivity('received redirect uri', {
    receivedRedirectUri: window.location.href
  });

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  if (loadOnSuccess && succeeded && exited) {
    return (
      <div className='oauth'>
        <Loader />
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  linkToken: _.get(state, ['bank', 'linkToken'])
});

const mapDispatchToProps = {
  runOnSuccess,
  runOnExit,
  runOnEvent
};

const ConnectedOauthRedirect = connect(mapStateToProps, mapDispatchToProps)(OauthRedirect);

export default ConnectedOauthRedirect;
