export const PATH_COMPONENT__SETTINGS = 'settings';
export const PATH_COMPONENT__DASHBOARD = 'dashboard';
export const PATH_COMPONENT__CONTACT_SUPPORT = 'contact-support';
export const PATH_COMPONENT__LINKED_ACCOUNTS = 'linked-accounts';
export const PATH_COMPONENT__PAST_RETURNS = 'past-returns';
export const PATH_COMPONENT__SWITCH_TO_DESKTOP = 'switch-to-desktop';
export const PATH_COMPONENT__EXPENSES = 'expenses';

export const SLUG__SETTINGS = 'settings';
export const SLUG__CONTACT_SUPPORT = 'contact-support';
export const SLUG__LINKED_ACCOUNTS = 'linked-accounts';
export const SLUG__PAST_RETURNS = 'past-returns';
export const SLUG__SWITCH_TO_DESKTOP = 'switch-to-desktop';

export const ENDPOINT_ATTRIBUTE__PAST_RETURNS = 'past-returns';
export const COLLECTION_TYPE__REVIEW = 'review';
export const ENDPOINT_ATTRIBUTE__REVIEW_STARTED = 'review-started';
export const TAXFLOW_BASE_URL = 'tax-filing';
export const DEFAULT_COLLECTION_ID = '0';
export const RESPONSE_TEXT_DELAY_MS = 3000;
export const SIGNED_URL_EXPIRATION_TIME_S = 300;

export const SECTION__SHARE_DETAILS = { getText: () => 'Share details', weight: 2 };
export const SECTION__KEEPER_REVIEW = { getText: ({ isMobile }) => (isMobile ? 'Review' : 'Keeper review'), weight: 1 };
export const SECTION__SIGN_AND_FILE = { getText: () => 'Sign & file', weight: 1 };

// ui stages
export const UI_STAGE__NOT_STARTED = 'Not Started';
export const UI_STAGE__PURGATORY = 'Purgatory';
export const UI_STAGE__OPS_REVIEW = 'Ops Review';
export const UI_STAGE__UPLOAD_ESC = 'Upload Esc';
export const UI_STAGE__CONFIRM_AMOUNTS = 'Confirm Amounts';
export const UI_STAGE__FINAL_REVIEW = 'Final Review';
export const UI_STAGE__FILED_WITH_IRS = 'Filed with IRS';
export const UI_STAGE__ACCEPTED_BY_IRS = 'Accepted by IRS';
export const UI_STAGE__USER_ESC = 'user escalation';
export const UI_STAGE__REJECTED_ESC = 'Rejected by IRS';
export const UI_STAGE__DO_NOT_FILE = 'DO NOT FILE';

// ui stages that result from ops substages
// in campaigns table the actual value is in this format: `${substageName} - ${prevUiStageName}`
// only the substage prefix is used for tax filing screen logic (except for 'Blocked - Final Review')
// `prevUiStage` is there so that the substage ui can be reverted when ops unchecks the substage in qdeck
export const UI_STAGE__BLOCKED_FINAL_REVIEW = 'Blocked - Final Review';
export const UI_STAGE__BLOCKED_PREFIX = 'Blocked';
export const UI_STAGE__EMAIL_OUTBOX_PREFIX = 'Email Outbox';

export const UI_STAGE_METADATA = [
  { stage: UI_STAGE__NOT_STARTED, preSubmit: true, editEnabled: true },
  {
    stage: UI_STAGE__PURGATORY,
    preSubmit: false,
    editEnabled: false,
    title: 'We’re checking your return',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 50 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    showReviewPills: true,
    submitButtonText: 'Reviewing...',
    submitButtonEnabled: false,
    showSubmitButton: true,
    showSubmitProgress: true
  },
  {
    stage: UI_STAGE__OPS_REVIEW,
    preSubmit: false,
    editEnabled: false,
    title: 'Our review is taking longer than expected',
    subTitle:
      'One of our tax experts is taking a second look at your return. We’ll send you a text when the expert is finished.',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 50 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    showReviewPills: true,
    submitButtonText: 'Reviewing...',
    submitButtonEnabled: false,
    showSubmitButton: true,
    showSubmitProgress: true
  },
  {
    stage: UI_STAGE__UPLOAD_ESC,
    preSubmit: false,
    editEnabled: false,
    title: 'Our review is taking longer than expected',
    subTitle:
      'One of our tax experts is taking a second look at your return. We’ll send you a text when the expert is finished.',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 50 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    showReviewPills: true,
    submitButtonText: 'Reviewing...',
    submitButtonEnabled: false,
    showSubmitButton: true,
    showSubmitProgress: true
  },
  {
    stage: UI_STAGE__CONFIRM_AMOUNTS,
    preSubmit: false,
    editEnabled: false,
    title: 'Your return has passed our review!',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 100 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    showReviewPills: true,
    submitButtonText: 'Sign & file',
    submitButtonEnabled: true,
    showSubmitProgress: false,
    showSubmitButton: true,
    showTaxBill: true,
    showSubmitWarnings: true
  },
  {
    stage: UI_STAGE__FINAL_REVIEW,
    preSubmit: false,
    editEnabled: false,
    title: 'You’re done! We’re sending your return to {irsAndState}',
    subTitle:
      'You made it! One of our tax experts is reviewing your return and will submit it, at which point you’ll receive a confirmation email it has been filed.',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 100 },
      { ...SECTION__SIGN_AND_FILE, progress: 100 }
    ],
    showReviewPills: false,
    showSubmitToIRSElement: true,
    showSubmitButton: false,
    supportedWhenTaxesClosed: true
  },
  {
    stage: UI_STAGE__FILED_WITH_IRS,
    preSubmit: false,
    editEnabled: false,
    title: 'Your taxes were sent to {irsAndState}',
    subTitle: 'It usually takes 1-2 days to hear back and we’ll send you a confirmation email once we do.',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 100 },
      { ...SECTION__SIGN_AND_FILE, progress: 100 }
    ],
    showReviewPills: false,
    showFiledWithIRSElement: true,
    showSubmitButton: false,
    supportedWhenTaxesClosed: true
  },
  {
    stage: UI_STAGE__ACCEPTED_BY_IRS,
    preSubmit: false,
    editEnabled: false,
    title: `Congratulations, you’re done with {taxFilingYear} taxes!`,
    subTitle: 'Your tax return has been accepted by {irsAndState}!',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 100 },
      { ...SECTION__SIGN_AND_FILE, progress: 100 }
    ],
    showReviewPills: false,
    showAcceptedByIRSElement: true,
    showSubmitButton: false,
    supportedWhenTaxesClosed: true
  },
  {
    stage: UI_STAGE__USER_ESC,
    title: 'We found some issues with your return',
    preSubmit: true,
    editEnabled: true,
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 50 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    showReviewPills: true,
    submitButtonText: 'Retry Keeper review',
    submitButtonEnabled: true,
    showSubmitButton: true,
    showSubmitWarnings: true
  },
  {
    stage: UI_STAGE__REJECTED_ESC,
    title: 'Your return was rejected by the IRS',
    preSubmit: true,
    editEnabled: true,
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 100 },
      { ...SECTION__KEEPER_REVIEW, progress: 50 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    showReviewPills: true,
    submitButtonText: 'Re-file return',
    submitButtonEnabled: true,
    showSubmitButton: true,
    showSubmitWarnings: true
  },
  {
    stage: UI_STAGE__DO_NOT_FILE,
    preSubmit: false,
    title: 'We can’t file your return',
    subTitle: `Unfortunately, we can’t file your return. Please contact support@keepertax.com for more details. \n\n For a fast refund of your subscription fee, contact refunds@keepertax.com.`,
    editEnabled: false,
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 0 },
      { ...SECTION__KEEPER_REVIEW, progress: 0 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    showReviewPills: false,
    showSubmitButton: false
  }
];

export const STATES = [
  {
    name: 'Alabama',
    code: 'AL'
  },
  {
    name: 'Alaska',
    code: 'AK'
  },
  {
    name: 'Arizona',
    code: 'AZ'
  },
  {
    name: 'Arkansas',
    code: 'AR'
  },
  {
    name: 'California',
    code: 'CA'
  },
  {
    name: 'Colorado',
    code: 'CO'
  },
  {
    name: 'Connecticut',
    code: 'CT'
  },
  {
    name: 'Delaware',
    code: 'DE'
  },
  {
    name: 'Florida',
    code: 'FL'
  },
  {
    name: 'Georgia',
    code: 'GA'
  },
  {
    name: 'Hawaii',
    code: 'HI'
  },
  {
    name: 'Idaho',
    code: 'ID'
  },
  {
    name: 'Illinois',
    code: 'IL'
  },
  {
    name: 'Indiana',
    code: 'IN'
  },
  {
    name: 'Iowa',
    code: 'IA'
  },
  {
    name: 'Kansas',
    code: 'KS'
  },
  {
    name: 'Kentucky',
    code: 'KY'
  },
  {
    name: 'Louisiana',
    code: 'LA'
  },
  {
    name: 'Maine',
    code: 'ME'
  },
  {
    name: 'Maryland',
    code: 'MD'
  },
  {
    name: 'Massachusetts',
    code: 'MA'
  },
  {
    name: 'Michigan',
    code: 'MI'
  },
  {
    name: 'Minnesota',
    code: 'MN'
  },
  {
    name: 'Mississippi',
    code: 'MS'
  },
  {
    name: 'Missouri',
    code: 'MO'
  },
  {
    name: 'Montana',
    code: 'MT'
  },
  {
    name: 'Nebraska',
    code: 'NE'
  },
  {
    name: 'Nevada',
    code: 'NV'
  },
  {
    name: 'New Hampshire',
    code: 'NH'
  },
  {
    name: 'New Jersey',
    code: 'NJ'
  },
  {
    name: 'New Mexico',
    code: 'NM',
    taxCollectionTypes: []
  },
  {
    name: 'New York',
    code: 'NY'
  },
  {
    name: 'North Carolina',
    code: 'NC'
  },
  {
    name: 'North Dakota',
    code: 'ND'
  },
  {
    name: 'Ohio',
    code: 'OH'
  },
  {
    name: 'Oklahoma',
    code: 'OK'
  },
  {
    name: 'Oregon',
    code: 'OR',
    taxCollectionTypes: []
  },
  {
    name: 'Pennsylvania',
    code: 'PA'
  },
  {
    name: 'Rhode Island',
    code: 'RI'
  },
  {
    name: 'South Carolina',
    code: 'SC'
  },
  {
    name: 'South Dakota',
    code: 'SD'
  },
  {
    name: 'Tennessee',
    code: 'TN'
  },
  {
    name: 'Texas',
    code: 'TX'
  },
  {
    name: 'Utah',
    code: 'UT'
  },
  {
    name: 'Vermont',
    code: 'VT'
  },
  {
    name: 'Virginia',
    code: 'VA'
  },
  {
    name: 'Washington',
    code: 'WA'
  },
  {
    name: 'Washington D.C.',
    code: 'DC'
  },
  {
    name: 'West Virginia',
    code: 'WV'
  },
  {
    name: 'Wisconsin',
    code: 'WI'
  },
  {
    name: 'Wyoming',
    code: 'WY'
  }
];

export const STATE_DROPDOWN_OPTIONS = [
  {
    text: 'Select a state...',
    value: ''
  },
  ...STATES.map((state) => ({
    text: state.name,
    value: state.code
  }))
];

export const STATE_NAME_MAP = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'Washington D.C.',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const STATE_TAX_COLL_TYPE_MAP = {
  AL: ['al-use-tax'],
  AK: [],
  AZ: [],
  AR: [],
  CA: ['ca-healthcare', 'ca-rent-credit', 'ca-use-tax'],
  CO: [],
  CT: ['ct-use-tax'],
  DE: [],
  FL: [],
  GA: [],
  HI: ['hi-housing', 'hi-rent-credit'],
  ID: [],
  IL: ['il-tuition', 'il-use-tax'],
  IN: ['in-county', 'in-school-district'],
  IA: ['ia-school-district'],
  KS: [],
  KY: [],
  LA: [],
  ME: ['me-bonus-depreciation', 'me-use-tax'],
  MD: [],
  MA: ['ma-health'],
  MI: ['mi-school-district', 'mi-use-tax'],
  MN: ['mn-tuition'],
  MS: [],
  MO: [],
  MT: [],
  NE: ['ne-school-district'],
  NV: [],
  NH: [],
  NJ: ['nj-county', 'nj-healthcare', 'nj-use-tax'],
  NM: [],
  NY: ['ny-county', 'ny-school-district', 'ny-use-tax', 'ny-living-duration'],
  NC: ['nc-use-tax'],
  ND: ['nd-income-source', 'nd-school-district'],
  OH: ['oh-school-district', 'oh-use-tax'],
  OK: ['ok-use-tax'],
  OR: ['or-surplus-credit'],
  PA: ['pa-w2-occupation', 'pa-school-district', 'pa-use-tax'],
  RI: ['ri-healthcare', 'ri-use-tax'],
  SC: ['sc-county', 'sc-use-tax'],
  SD: [],
  TN: [],
  TX: [],
  UT: ['ut-use-tax'],
  VT: ['vt-school-district', 'vt-use-tax'],
  VA: ['va-county', 'va-use-tax'],
  WA: [],
  DC: ['dc-healthcare', 'dc-rent-credit'],
  WV: ['wv-county', 'wv-use-tax'],
  WI: ['wi-school-district', 'wi-use-tax'],
  WY: []
};

export const NO_INCOME_TAX_STATES = new Set(['AK', 'FL', 'NV', 'SD', 'TN', 'TX', 'WA', 'WY']);

export const SPECIAL_CHARS = '!",@#$%^&*+=';
