import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxFlowMultiOptionListItem from '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionListItem';
import { onboardingEstimateTimeSelector, onboardingExperimentsSelector } from '@app/src/selectors/onboardingSelectors';
import { multiOptionExplanationSelector } from '@app/src/selectors/taxFlowSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionElement.scss';

class TaxFlowMultiOptionElement extends Component {
  render() {
    const { currentQuestion, resultLoading, isChildQuestion, explanation } = this.props;

    if (resultLoading) {
      return null;
    }

    return (
      <div className={classNames({ 'steps-body': !isChildQuestion })}>
        <div className='checkbox-list'>
          {currentQuestion.question_meta &&
            currentQuestion.question_meta.map((item) => {
              return (
                <TaxFlowMultiOptionListItem
                  currentQuestion={currentQuestion}
                  item={item}
                  key={item.value}
                  showRightIcon={_.get(item, 'info')}
                  {...this.props}
                />
              );
            })}
        </div>
        {explanation && <div className='tax-flow-multi-option-element-info'>{explanation}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentQuestion: props.currentQuestion || state.taxFlow.currentQuestion,
  resultLoading: !_.isNil(props.resultLoading) ? props.resultLoading : state.taxFlow.resultLoading,
  estimateTime: onboardingEstimateTimeSelector(state),
  experiments: onboardingExperimentsSelector(state),
  explanation: multiOptionExplanationSelector(state)
});

const ConnectedTaxFlowMultiOptionElement = connect(mapStateToProps)(TaxFlowMultiOptionElement);

export default ConnectedTaxFlowMultiOptionElement;
