import React from 'react';
import _ from 'lodash';
import { TickCircle } from 'iconsax-react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { subscriptionDefaultsSelector } from '@app/src/selectors/pricingSelectors';
import { colorNeutralGainsboro, colorSuccess } from '@app/src/theme';
import '@app/src/Components/Subscription/SubscriptionContent/FreeTrialTimeline.scss';

const FreeTrialTimeline = () => {
  const defaultValues = useSelector((state) => subscriptionDefaultsSelector(state, 'subscriptionBenefits'));
  const stepOneTitle = _.get(defaultValues, ['timeline', 'stepOne', 'title']);
  const stepOneText = _.get(defaultValues, ['timeline', 'stepOne', 'text']);
  const stepTwoTitle = _.get(defaultValues, ['timeline', 'stepTwo', 'title']);
  const stepTwoText = _.get(defaultValues, ['timeline', 'stepTwo', 'text']);
  const stepThreeTitle = _.get(defaultValues, ['timeline', 'stepThree', 'title']);
  const stepThreeText = _.get(defaultValues, ['timeline', 'stepThree', 'text']);

  return (
    <div className='free-trial-container'>
      <div className='free-trial-component'>
        <div className='flex row mb-20'>
          <div className='free-trial-icon'>
            <TickCircle size='24' color={colorSuccess} variant='Bold' />
            <div
              className='free-trial-vertical-line free-trial-vertical-line-green'
              style={{ backgroundColor: colorSuccess }}
            />
          </div>
          <div>
            <div className='text-base bold'>{stepOneTitle ? stepOneTitle : 'Today: Get instant access'}</div>
            <div className='base-label'>
              {stepOneText ? stepOneText : "Get your deductions and find out how much money you'll save!"}
            </div>
          </div>
        </div>
        <div className='flex row mb-20'>
          <div className='free-trial-icon'>
            <TickCircle size='24' color={colorNeutralGainsboro} />
            <div className='free-trial-vertical-line free-trial-vertical-line-grey' />
          </div>
          <div>
            <div className='text-base bold'>
              {stepTwoTitle ? stepTwoTitle : `Your free trial ends on ${moment().add(7, 'days').format('MMM DD')}`}
            </div>
            <div className='base-label'>{stepTwoText ? stepTwoText : 'Easily cancel anytime if not satisfied.'}</div>
          </div>
        </div>
        <div className='flex row'>
          <div className='free-trial-icon'>
            <TickCircle size='24' color={colorNeutralGainsboro} />
          </div>
          <div>
            <div className='text-base bold'>{stepThreeTitle ? stepThreeTitle : 'At tax time'}</div>
            <div className='base-label'>
              {stepThreeText
                ? stepThreeText
                : 'File with Keeper or easily export your deductions, your largest tax refund guaranteed!'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeTrialTimeline;
