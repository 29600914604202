import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const TaxFlowInfoItem = ({ currentQuestion }) => {
  const link = _.get(currentQuestion, ['question_meta', 'link']);
  if (!link) {
    return null;
  }

  const path = _.get(link, 'path', '');
  if (!path) {
    throw new Error('Could not resolve path');
  }

  return (
    <Link
      to={path}
      component={({ navigate }) => {
        return (
          <div className='steps-body'>
            <Button fullWidth variant='contained' color='secondary' onClick={navigate}>
              {link.label}
            </Button>
          </div>
        );
      }}
    />
  );
};

export default TaxFlowInfoItem;
