import _ from 'lodash';
import { createSelector } from 'reselect';
import taxDataApi, { jobsSelector } from '@app/src/api/taxDataApi';
import { ACTIVE_USER_STATUSES } from '@app/src/constants/constants';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import { hasQuestionSpecialChars, toTitleCase } from '@app/src/global/Helpers';
import { savingsSelector } from '@app/src/selectors/dashboardSelectors';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';
import isValidInput from '@app/src/services/taxFlow/isValidInput';
import { getDerivedQuestion, getJobData, getSubstitutions } from '@app/src/taxflow/main/utils/mainUtils';
import { CAR_ENDPOINT_ATTRIBUTES, CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import {
  COLLECTION_TYPE__CREDIT_CHARITY,
  COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED,
  ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST,
  SLUG__CREDIT_COGS_END_INVENTORY,
  SLUG__CREDIT_COGS_NEW_INVENTORY,
  SLUG__CREDIT_HEALTH_HAS_1095A,
  SLUG__CREDIT_STANDARD_CHARITY,
  SLUG__CREDIT_STANDARD_HEALTH_INSURANCE,
  SLUG__CREDIT_STANDARD_MORTGAGE_INTEREST
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__HOME,
  HOME_ENDPOINT_ATTRIBUTES,
  HOME_SLUGS
} from '@app/src/taxflow/sections/home/homeConstants';
import {
  SLUG__HOME_ADDRESS_STATE,
  SLUG__SELF_FIRST_NAME,
  SLUG__SELF_LAST_NAME,
  SLUG__SELF_TAX_STATUS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { DEFAULT_COLLECTION_ID, UI_STAGE_METADATA } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  cogsSummarySelector,
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  currentTaxStateSelector,
  deductionsSelector,
  queryResultsSelector,
  statusSelector,
  taxFilePriceSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  getFilingStatus,
  getQueryResultByEndpointAttribute,
  getQueryResultValueByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';

export const taxDataSelector = (state) => {
  const year = yearSelector(state);
  const { data } = taxDataApi.endpoints.getTaxInfo.select({ year })(state);
  return _.get(data, 'taxData', []);
};

export const uiStageSelector = (state) => {
  const year = yearSelector(state);
  const { data: uiStage } = taxDataApi.endpoints.getUIStage.select({ year })(state);
  return uiStage;
};

export const questionnairePassedQuestionsSelector = (state) => {
  const year = yearSelector(state);
  const { data } = taxDataApi.endpoints.getQuestionnaireProgress.select({ year })(state);
  return _.get(data, 'passedQuestions');
};

export const questionnaireTotalQuestionsSelector = (state) => {
  const year = yearSelector(state);
  const { data } = taxDataApi.endpoints.getQuestionnaireProgress.select({ year })(state);
  return _.get(data, 'totalQuestions');
};

export const submitIssueItemsSelector = (state) => {
  const year = yearSelector(state);
  const { data: submitIssues } = taxDataApi.endpoints.getSubmitIssues.select({ year })(state);
  return _.defaultTo(submitIssues, []);
};
export const submitWarningsSelector = (state) => {
  const year = yearSelector(state);
  const { data: submitWarnings } = taxDataApi.endpoints.getSubmitWarnings.select({ year })(state);
  return _.defaultTo(submitWarnings, []);
};

export const taxAmountsSelector = (state) => {
  const year = yearSelector(state);
  const { data: taxAmounts } = taxDataApi.endpoints.getTaxAmounts.select({ year })(state);
  return taxAmounts;
};

export const questionSelector = (state, props) => props.question;
export const allQuestionsSelector = (state) => _.get(state, ['taxFlow', 'allQuestions']);
const carDepreciationSelector = (state) => _.get(state, ['taxFlow', 'carPriorDepreciation']);
const homeDepreciationSelector = (state) => _.get(state, ['taxFlow', 'homePriorDepreciation']);
export const accountDetailsSelector = (state) => _.get(state, ['pricing2', 'accountDetails'], {});
// Same as expensesYearSelector
export const yearSelector = (state) => _.get(state, ['expenseReview', 'expensesYear']);

export const savedDefaultAnswerSelector = createSelector(
  [
    questionSelector,
    workDetailsSelector,
    userSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    cogsSummarySelector,
    carDepreciationSelector,
    homeDepreciationSelector,
    deductionsSelector
  ],
  (
    question,
    workDetails,
    user,
    queryResults,
    currentCollectionId,
    cogsSummary,
    carPriorDepreciation,
    homePriorDepreciation,
    deductions
  ) => {
    const getDefaultValue = () => {
      if (question.slug === CAR_SLUGS.MILEAGE_ESTIMATION_PRICE_PER_GALLON) {
        return '3.50';
      }
      if (question.slug === CAR_SLUGS.MILEAGE_ESTIMATION_MILES_PER_GALLON) {
        return 24;
      }
      if (question.slug === CAR_SLUGS.MILEAGE_ESTIMATION_WORK_PERCENT) {
        return workDetails.percent_car;
      }
      if (question.slug === SLUG__SELF_FIRST_NAME) {
        return user.firstname;
      }
      if (question.slug === SLUG__SELF_LAST_NAME) {
        return user.lastname;
      }
      if (question.slug === SLUG__HOME_ADDRESS_STATE) {
        return workDetails.state_residence;
      }
      if (question.slug === SLUG__SELF_TAX_STATUS) {
        return workDetails.filing_status;
      }
      if (question.slug === CAR_SLUGS.DEPRECIATION_AMOUNT_ESTIMATE) {
        return carPriorDepreciation;
      }
      if (question.slug === HOME_SLUGS.PRIOR_DEPRECIATION) {
        return homePriorDepreciation;
      }
      if (question.slug === SLUG__CREDIT_COGS_NEW_INVENTORY || question.slug === SLUG__CREDIT_COGS_END_INVENTORY) {
        return _.get(cogsSummary, 'inventory');
      }
      if (question.slug === SLUG__CREDIT_HEALTH_HAS_1095A) {
        const attempted1095AInput = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
            collectionId: DEFAULT_COLLECTION_ID,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED
          }),
          ['answer', 'value']
        );
        return attempted1095AInput ? '1' : '0';
      }
      if (question.slug === SLUG__CREDIT_STANDARD_HEALTH_INSURANCE) {
        return _.get(deductions, ['itemizedDefaults', 'yearlyPremium']);
      }
      if (question.slug === SLUG__CREDIT_STANDARD_MORTGAGE_INTEREST) {
        const mortgageInterest = _.chain(queryResults)
          .filter({
            coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
          })
          .map((result) => _.get(result, ['answer', 'value']))
          .sumBy(Number)
          .value();

        if (mortgageInterest) {
          return mortgageInterest;
        }
      }
      if (question.slug === HOME_SLUGS.EXPENSES_MORTGAGE_INTEREST) {
        const mortgageInterest = _.chain(queryResults)
          .filter({
            coll_type: COLLECTION_TYPE__CREDIT_HOMEOWNER,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST
          })
          .map((result) => _.get(result, ['answer', 'value']))
          .sumBy(Number)
          .value();

        const duration = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: COLLECTION_TYPE__HOME,
            collectionId: currentCollectionId,
            slug: HOME_ENDPOINT_ATTRIBUTES.DATES
          }),
          ['answer']
        );

        const numberOfMonths = Number(duration.value) === 1 ? duration.months.length : 12;

        if (mortgageInterest) {
          return mortgageInterest / numberOfMonths;
        }
      }
      if (question.slug === SLUG__CREDIT_STANDARD_CHARITY) {
        const donations = _.get(
          getQueryResultByEndpointAttribute({
            queryResults,
            collectionType: COLLECTION_TYPE__CREDIT_CHARITY,
            collectionId: DEFAULT_COLLECTION_ID,
            slug: ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_AMOUNT
          }),
          ['answer', 'value']
        );

        if (donations) {
          return donations;
        }
      }
      return null;
    };
    const defaultValue = getDefaultValue();
    if (defaultValue === null) {
      return null;
    }
    // Don't suggest default if value not valid;
    if (hasQuestionSpecialChars(defaultValue, question)) {
      return null;
    }
    return { value: defaultValue };
  }
);

const submitEnabledSelector = (state) => {
  const navigationItems = _.get(state, ['taxFlowNavigation', 'navigationItems']);
  const queryResults = _.get(state, ['taxFlow', 'queryResults']);

  if (REACT_APP_ENV === 'staging-prod-db' || REACT_APP_ENV === 'development') {
    return true;
  }

  const submitNavItem = navigationItems.find((item) => item.id === 'submit');
  const submitEnabledStatus = _.get(submitNavItem, ['meta', 'submitEnabled']);

  const states = queryResults
    .filter(
      (queryResult) =>
        queryResult.coll_type === COLLECTION_TYPE__STATE_RETURN && queryResult.slug === SLUG__STATE_RETURN
    )
    .map((state) => _.get(state, ['answer', 'value']));

  return states.every((state) => _.get(submitEnabledStatus, state));
};

export const isPremiumSubscriberSelector = createSelector(accountDetailsSelector, (accountDetails) =>
  _.get(accountDetails, 'isPremiumSubscriber', false)
);

export const substitutionsSelector = createSelector(
  [
    currentQuestionSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    taxFilePriceSelector,
    workDetailsSelector,
    taxAmountsSelector,
    jobCategoriesSelector,
    deductionsSelector,
    currentTaxStateSelector,
    savingsSelector,
    submitEnabledSelector,
    jobsSelector,
    isPremiumSubscriberSelector,
    yearSelector
  ],
  (
    currentQuestion,
    queryResults,
    currentCollectionId,
    taxFilePrice,
    workDetails,
    taxAmounts,
    jobCategories,
    deductions,
    currentTaxState,
    savings,
    submitEnabled,
    jobs,
    isPremiumSubscriber,
    year
  ) =>
    getSubstitutions({
      question: currentQuestion,
      queryResults,
      collectionId: currentCollectionId,
      taxFilePrice,
      workDetails,
      taxAmounts,
      jobCategories,
      deductions,
      taxState: currentTaxState,
      savings,
      submitEnabled,
      jobs,
      isPremiumSubscriber,
      year
    })
);

export const substitutionsWithQuestionSelector = createSelector(
  [
    questionSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    taxFilePriceSelector,
    workDetailsSelector,
    taxAmountsSelector,
    jobCategoriesSelector,
    deductionsSelector,
    currentTaxStateSelector,
    submitEnabledSelector,
    jobsSelector,
    isPremiumSubscriberSelector,
    yearSelector
  ],
  (
    question,
    queryResults,
    currentCollectionId,
    taxFilePrice,
    workDetails,
    taxAmounts,
    jobCategories,
    deductions,
    currentTaxState,
    submitEnabled,
    jobs,
    isPremiumSubscriber,
    year
  ) =>
    getSubstitutions({
      question,
      queryResults,
      collectionId: currentCollectionId,
      taxFilePrice,
      workDetails,
      taxAmounts,
      jobCategories,
      deductions,
      taxState: currentTaxState,
      submitEnabled,
      jobs,
      isPremiumSubscriber,
      year
    })
);

/**
 * Formats job data into option objects.
 */
const jobOptionsSelector = createSelector([jobsSelector], (jobs) => {
  if (jobs) {
    return _.chain(jobs)
      .map((job) => {
        return {
          text: job.name,
          value: job.name,
          businessCode: job.businessCode,
          iconUrl: job.iconUrl
        };
      })
      .uniqBy('value')
      .value();
  }
  return [];
});

export const allDerivedQuestionsSelector = createSelector(
  [allQuestionsSelector, jobOptionsSelector],
  (allQuestions, jobOptions) => {
    return allQuestions.map((question) => getDerivedQuestion({ question, jobOptions }));
  }
);

export const isValidInputSelector = createSelector(
  [
    currentQuestionSelector,
    currentAnswerSelector,
    statusSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    yearSelector
  ],
  (question, answer, status, queryResults, currentCollectionId, taxYear) =>
    isValidInput({ question, answer, status, queryResults, currentCollectionId, taxYear })
);

export const hasActiveSubscriptionSelector = createSelector([accountDetailsSelector], (accountDetails) =>
  ACTIVE_USER_STATUSES.includes(_.get(accountDetails, 'status'))
);

export const zeroTaxOwedSelector = createSelector([taxAmountsSelector], (taxAmounts) => {
  const allMultistateZero = taxAmounts?.multistate?.every(({ amount }) => amount === 0) ?? true;
  return Number(taxAmounts?.federal_amount) === 0 && allMultistateZero;
});

export const isPreSubmitSelector = createSelector([uiStageSelector], (uiStage) => {
  if (_.isNil(uiStage) || _.isEmpty(uiStage)) {
    return true;
  }
  return _.some(UI_STAGE_METADATA, { stage: uiStage, preSubmit: true });
});

const getCarMileageSpecificQuestions = ({ queryResults, currentQuestion }) => {
  const carQueryResults = queryResults.filter((item) => item.coll_type === COLLECTION_TYPE__CAR);
  const carCollectionIds = _.uniq(carQueryResults.map((r) => r.coll_id).filter((id) => Number(id) > 0)).sort();
  const jobs = getJobData({ queryResults });
  const filingStatus = getFilingStatus({ queryResults });
  const numUniquePersons = _.uniq(jobs.map((j) => j.who)).length;

  const questions = _.flatMap(carCollectionIds, (collectionId) => {
    const parentTitle =
      carCollectionIds.length > 1
        ? getQueryResultValueByEndpointAttribute({
            queryResults: carQueryResults,
            collectionType: COLLECTION_TYPE__CAR,
            collectionId,
            slug: CAR_ENDPOINT_ATTRIBUTES.BRAND
          }) || `Vehicle ${collectionId}`
        : null;
    const subquestions = _.flatMap(currentQuestion.sub_question, (subquestion) => {
      if (subquestion.slug !== CAR_SLUGS.MILEAGE_SPECIFIC_BUSINESS_MILEAGE) {
        return {
          ...subquestion,
          slug: `${subquestion.slug}-${collectionId}`,
          endpoint_attr: `${subquestion.slug}-${collectionId}`
        };
      } else {
        const getTitle = (job) => {
          if (jobs.length === 1) {
            return `${subquestion.title}`;
          }
          // The job title should be in title case
          const jobTitle = toTitleCase(job.jobName.replaceAll('-', ' '));

          // If there was more than one person earning 1099 income, add prefix to title to delimit who earned it
          if (numUniquePersons > 1 && filingStatus === 'married') {
            return `${job.who === 'me' ? 'Your' : "Your spouse's"} ${_.toLower(subquestion.title)} (${jobTitle})`;
          }
          return `${subquestion.title} (${jobTitle})`;
        };
        return [
          ...jobs.map((job) => {
            // The job needs to have the dashes removed from the name in order to be processed correctly later
            const jobName = job.jobName.replaceAll('-', '');
            return {
              ...subquestion,
              slug: `${subquestion.slug}-${job.who}-${jobName}-${collectionId}`,
              endpoint_attr: `${subquestion.slug}-${job.who}-${jobName}-${collectionId}`,
              title: getTitle(job)
            };
          })
        ];
      }
    });
    return {
      ...currentQuestion,
      title: parentTitle,
      slug: `${currentQuestion.slug}-${collectionId}`,
      endpoint_attr: `${currentQuestion.slug}-${collectionId}`,
      sub_question: subquestions
    };
  });
  return questions;
};

export const formMultiQuestionsSelector = (state, currentQuestion) =>
  createSelector([queryResultsSelector, currentQuestionSelector], (queryResults, parentQuestion) => {
    if (parentQuestion.slug === CAR_SLUGS.MILEAGE_SPECIFIC) {
      return getCarMileageSpecificQuestions({ queryResults, currentQuestion });
    }
  })(state);
