import { useMemo } from 'react';
import moment from 'moment';
import { useGetAppYearsQuery } from '@app/src/api/profileApi';

const useYears = () => {
  const getAppYearsResult = useGetAppYearsQuery();

  const years = useMemo(() => {
    if (getAppYearsResult.data) {
      return getAppYearsResult.data;
    }

    if (getAppYearsResult.isLoading || getAppYearsResult.isUninitialized) {
      return [];
    }

    return [moment().year().toString(), (moment().year() - 1).toString()];
  }, [getAppYearsResult.data, getAppYearsResult.isLoading, getAppYearsResult.isUninitialized]);

  return years;
};

export default useYears;
