import { baseApi, TAGS } from '@app/src/api/baseApi';
import { getFulfilledRequestData } from '@app/src/api/utils';

const plaidApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaid: builder.query({
      query: () => ({
        url: 'plaid/get-tax-filing-purchase-status',
        method: 'GET'
      }),
      providesTags: [TAGS.PAID],
      transformResponse: (response) => response.data.paid
    })
  })
});

export default plaidApi;

export const { useGetPaidQuery } = plaidApi;

// Actions
export const getPaid = () => async (dispatch) =>
  getFulfilledRequestData({ initiateFunction: plaidApi.endpoints.getPaid.initiate, dispatch });
