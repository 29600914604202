import React from 'react';
import SubscriptionFeature from '@app/src/Components/Subscription/Common/SubscriptionFeature';
import '@app/src/Components/Subscription/Common/SubscriptionFeature.scss';

const SubscriptionFeatures = () => (
  <div className='subscription-features' style={{ margin: 16 }}>
    <SubscriptionFeature style={{ marginBottom: '8px' }} text='Year-round expense tracking' included={true} />
    <SubscriptionFeature style={{ marginBottom: '8px' }} text='Keeper finds your eligible deductions' included={true} />
    <SubscriptionFeature style={{ marginBottom: '8px' }} text='Save $1,249 in taxes on average' included={true} />
  </div>
);

export default SubscriptionFeatures;
