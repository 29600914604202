export const PRICING2__SET_SETTINGS = 'PRICING2__SET_SETTINGS';
export const PRICING2__SET_BANK_LINKS = 'PRICING2__SET_BANK_LINKS';
export const PRICING2__SET_ACCOUNT_DETAILS = 'PRICING2__SET_ACCOUNT_DETAILS';
export const PRICING2__SET_LOADING = 'PRICING2__SET_LOADING';
export const PRICING2__SET_UPDATING = 'PRICING2__SET_UPDATING';
export const PRICING2__SET_ERROR = 'PRICING2__SET_ERROR';
export const PRICING2__SET_WALLET_TYPE = 'PRICING2__SET_WALLET_TYPE';
export const PRICING2__SET_IS_CARD_VALID = 'PRICING2__SET_IS_CARD_VALID';
export const PRICING2__SET_BANK = 'PRICING2__SET_BANK';
export const PRICING2__SET_ORIGIN = 'PRICING2__SET_ORIGIN';
export const PRICING2__SET_SUBSCRIPTION_TYPE = 'PRICING2__SET_SUBSCRIPTION_TYPE';
export const PRICING2__SET_SUBSCRIPTION_INFO = 'PRICING2__SET_SUBSCRIPTION_INFO';
export const PRICING2__SET_SUBSCRIPTION_TYPES = 'PRICING2__SET_SUBSCRIPTION_TYPES';
export const PRICING2__SET_PLANS_SHOWN = 'PRICING2__SET_PLANS_SHOWN';
export const PRICING2__SET_OVERRIDES = 'PRICING2__SET_OVERRIDES';

export const PAYMENT_ERROR_MESSAGE =
  'There was an error processing the payment. Please try again or switch to a new payment method.';

/** Potential origins for the subscription modals */
export const PRICING_ORIGINS = {
  EDIT_PAYMENT: 'edit_payment',
  EDIT_PLAN: 'edit_plan',
  EXPENSE_REVIEW: 'expense-review',
  EXPORT: 'export',
  SETTINGS: 'settings',
  SUBSCRIPTION_FLOW: 'subscription',
  /** Paywall that shows in submit flow that hard blocks users who do not have active annual/premium subscription */
  TAX_FILING_HARD_PAYWALL: 'tax filing hard paywall',
  /** Paywall we show to users who require premium to proceed to Keeper Review */
  TAX_FILING_PREMIUM_PAYWALL: 'tax filing premium paywall',
  /** Paywall we show users that are pre-trial */
  TAX_FILING_TRIAL_PAYWALL: 'tax filing trial paywall',
  WEB_PAYWALL: 'web-paywall'
};

export const PRICING_ORIGIN__UPGRADE_ORIGINS = [
  PRICING_ORIGINS.EDIT_PLAN,
  PRICING_ORIGINS.EXPENSE_REVIEW,
  PRICING_ORIGINS.SETTINGS,
  PRICING_ORIGINS.SUBSCRIPTION_FLOW,
  PRICING_ORIGINS.TAX_FILING_HARD_PAYWALL,
  PRICING_ORIGINS.TAX_FILING_PREMIUM_PAYWALL,
  PRICING_ORIGINS.TAX_FILING_TRIAL_PAYWALL,
  PRICING_ORIGINS.WEB_PAYWALL
];
