export const PATH_COMPONENT__STATE_DEFAULT = 'state-default';
export const PATH_COMPONENT__STATE_NO_INCOME_TAX = 'state-no-income-tax';
export const PATH_COMPONENT__STATE_RESIDENCY = 'state-residency';
export const PATH_COMPONENT__STATE_INCOME = 'state-income';
export const PATH_COMPONENT__STATE_EXPENSES = 'state-expenses';
export const PATH_COMPONENT__STATE_RETURN = 'state-return';

export const PATH_COMPONENT__CA_USE_TAX_PURCHASES = 'ca-use-tax-purchases';
export const PATH_COMPONENT__CA_USE_TAX_AMOUNT = 'ca-use-tax-amount';
export const PATH_COMPONENT__CA_RENT_CREDIT = 'ca-rent-credit';
export const PATH_COMPONENT__CA_RENT_CREDIT_DEPENDENT = 'ca-rent-credit-dependent';
export const PATH_COMPONENT__CA_RENT_CREDIT_PROPERTY = 'ca-rent-credit-property';
export const PATH_COMPONENT__CA_RENT_CREDIT_TAX = 'ca-rent-credit-tax';
export const PATH_COMPONENT__CA_RENT_CREDIT_SUCCESS = 'ca-rent-credit-success';
export const PATH_COMPONENT__CA_HEALTHCARE = 'ca-healthcare';
export const PATH_COMPONENT__CA_HEALTHCARE_SELF_DURATION = 'ca-healthcare-self-duration';
export const PATH_COMPONENT__CA_HEALTHCARE_SPOUSE_DURATION = 'ca-healthcare-spouse-duration';
export const PATH_COMPONENT__CA_HEALTHCARE_DEPENDENT_DURATION = 'ca-healthcare-dependent-duration';
export const PATH_COMPONENT__CA_DONE = 'ca-done';

export const PATH_COMPONENT__DC_RENT_CREDIT_OPTIONS = 'dc-rent-credit-options';
export const PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TYPE = 'dc-rent-credit-property-type';
export const PATH_COMPONENT__DC_RENT_CREDIT_AMOUNT = 'dc-rent-credit-amount';
export const PATH_COMPONENT__DC_RENT_CREDIT_LANDLORD_INFO = 'dc-rent-credit-landlord-info';
export const PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TAX = 'dc-rent-credit-property-tax';
export const PATH_COMPONENT__DC_HEALTHCARE = 'dc-healthcare';
export const PATH_COMPONENT__DC_HEALTHCARE_SELF_DURATION = 'dc-healthcare-self-duration';
export const PATH_COMPONENT__DC_HEALTHCARE_SPOUSE_DURATION = 'dc-healthcare-spouse-duration';
export const PATH_COMPONENT__DC_HEALTHCARE_DEPENDENT_DURATION = 'dc-healthcare-dependent-duration';
export const PATH_COMPONENT__DC_DONE = 'dc-done';

export const PATH_COMPONENT__HI_HOUSING_DISTRIBUTION = 'hi-housing-distribution';
export const PATH_COMPONENT__HI_HOUSING_INFO = 'hi-housing-info';
export const PATH_COMPONENT__HI_RENT_CREDIT_QUALIFY = 'hi-rent-credit-qualify';
export const PATH_COMPONENT__HI_RENT_CREDIT_INFO = 'hi-rent-credit-info';
export const PATH_COMPONENT__HI_DONE = 'hi-done';

export const PATH_COMPONENT__NY_SCHOOL_DISTRICT = 'ny-school-district';
export const PATH_COMPONENT__NY_COUNTY = 'ny-county';
export const PATH_COMPONENT__NY_LIVING_DURATION = 'ny-living-duration';
export const PATH_COMPONENT__NY_USE_TAX_PURCHASES = 'ny-use-tax-purchases';
export const PATH_COMPONENT__NY_USE_TAX_AMOUNT = 'ny-use-tax-amount';
export const PATH_COMPONENT__NY_DONE = 'ny-done';

export const PATH_COMPONENT__NJ_COUNTY = 'nj-county';
export const PATH_COMPONENT__NJ_HEALTHCARE = 'nj-healthcare';
export const PATH_COMPONENT__NJ_HEALTHCARE_SELF_DURATION = 'nj-healthcare-self-duration';
export const PATH_COMPONENT__NJ_HEALTHCARE_SPOUSE_DURATION = 'nj-healthcare-spouse-duration';
export const PATH_COMPONENT__NJ_HEALTHCARE_DEPENDENT_DURATION = 'nj-healthcare-dependent-duration';
export const PATH_COMPONENT__NJ_USE_TAX_PURCHASES = 'nj-use-tax-purchases';
export const PATH_COMPONENT__NJ_DONE = 'nj-done';

export const PATH_COMPONENT__AL_USE_TAX_PURCHASES = 'al-use-tax-purchases';
export const PATH_COMPONENT__AL_USE_TAX_AMOUNT = 'al-use-tax-amount';
export const PATH_COMPONENT__AL_DONE = 'al-done';

export const PATH_COMPONENT__CT_USE_TAX_PURCHASES = 'ct-use-tax-purchases';
export const PATH_COMPONENT__CT_USE_TAX_AMOUNT = 'ct-use-tax-amount';
export const PATH_COMPONENT__CT_DONE = 'ct-done';

export const PATH_COMPONENT__ID_TUITION = 'id-tuition';
export const PATH_COMPONENT__ID_TUITION_AMOUNT = 'id-tuition-amount';
export const PATH_COMPONENT__ID_DONE = 'id-done';

export const PATH_COMPONENT__IL_USE_TAX_PURCHASES = 'il-use-tax-purchases';
export const PATH_COMPONENT__IL_USE_TAX_AMOUNT = 'il-use-tax-amount';
export const PATH_COMPONENT__IL_TUITION = 'il-tuition';
export const PATH_COMPONENT__IL_TUITION_INFO = 'il-tuition-info';
export const PATH_COMPONENT__IL_DONE = 'il-done';

export const PATH_COMPONENT__IN_COUNTY_LIVED = 'in-county-lived';
export const PATH_COMPONENT__IN_COUNTY_WORKED = 'in-county-worked';
export const PATH_COMPONENT__IN_COUNTY_SPOUSE_WORKED = 'in-county-spouse-worked';
export const PATH_COMPONENT__IN_SCHOOL_DISTRICT = 'in-school-district';
export const PATH_COMPONENT__IN_DONE = 'in-done';

export const PATH_COMPONENT__IA_SCHOOL_DISTRICT = 'ia-school-district';
export const PATH_COMPONENT__IA_DONE = 'ia-done';

export const PATH_COMPONENT__KY_USE_TAX_PURCHASES = 'ky-use-tax-purchases';
export const PATH_COMPONENT__KY_USE_TAX_PRICE = 'ky-use-tax-price';
export const PATH_COMPONENT__KY_REVIEW = 'ky-review';
export const PATH_COMPONENT__KY_DONE = 'ky-done';

export const PATH_COMPONENT__MI_SCHOOL_DISTRICT = 'mi-school-district';
export const PATH_COMPONENT__MI_USE_TAX_PURCHASES = 'mi-use-tax-purchases';
export const PATH_COMPONENT__MI_USE_TAX_AMOUNT = 'mi-use-tax-amount';
export const PATH_COMPONENT__MI_DONE = 'mi-done';

export const PATH_COMPONENT__MN_TUITION = 'mn-tuition';
export const PATH_COMPONENT__MN_TUITION_INSTITUTION = 'mn-tuition-institution';
export const PATH_COMPONENT__MN_TUITION_ACCOUNT = 'mn-tuition-account';
export const PATH_COMPONENT__MN_TUITION_AMOUNT = 'mn-tuition-amount';
export const PATH_COMPONENT__MN_DONE = 'mn-done';

export const PATH_COMPONENT__NC_USE_TAX_PURCHASES = 'nc-use-tax-purchases';
export const PATH_COMPONENT__NC_USE_TAX_AMOUNT = 'nc-use-tax-amount';
export const PATH_COMPONENT__NC_DONE = 'nc-done';

export const PATH_COMPONENT__NE_SCHOOL_DISTRICT = 'ne-school-district';
export const PATH_COMPONENT__NE_DONE = 'ne-done';

export const PATH_COMPONENT__ND_SCHOOL_DISTRICT = 'nd-school-district';
export const PATH_COMPONENT__ND_INCOME_SOURCE = 'nd-income-source';
export const PATH_COMPONENT__ND_DONE = 'nd-done';

export const PATH_COMPONENT__OH_SCHOOL_DISTRICT = 'oh-school-district';
export const PATH_COMPONENT__OH_USE_TAX_PURCHASES = 'oh-use-tax-purchases';
export const PATH_COMPONENT__OH_USE_TAX_AMOUNT = 'oh-use-tax-amount';
export const PATH_COMPONENT__OH_DONE = 'oh-done';

export const PATH_COMPONENT__OK_USE_TAX_PURCHASES = 'ok-use-tax-purchases';
export const PATH_COMPONENT__OK_USE_TAX_AMOUNT = 'ok-use-tax-amount';
export const PATH_COMPONENT__OK_DONE = 'ok-done';

export const PATH_COMPONENT__OR_SURPLUS_INTRO = 'or-surplus-intro';
export const PATH_COMPONENT__OR_SURPLUS_LIABILITY = 'or-surplus-liability';
export const PATH_COMPONENT__OR_SURPLUS_PRIOR_STATUS = 'or-surplus-prior-status';
export const PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE = 'or-surplus-liability-amount-single';
export const PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED = 'or-surplus-liability-amount-newly-married';
export const PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_JOINT = 'or-surplus-liability-amount-joint';
export const PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED = 'or-surplus-liability-amount-separated';
export const PATH_COMPONENT__OR_SURPLUS_AGI = 'or-surplus-agi';
export const PATH_COMPONENT__OR_DONE = 'or-done';

export const PATH_COMPONENT__PA_SCHOOL_DISTRICT = 'pa-school-district';
export const PATH_COMPONENT__PA_W2_OCCUPATION = 'pa-w2-occupation';
export const PATH_COMPONENT__PA_USE_TAX_PURCHASES = 'pa-use-tax-purchases';
export const PATH_COMPONENT__PA_DONE = 'pa-done';

export const PATH_COMPONENT__RI_HEALTHCARE = 'ri-healthcare';
export const PATH_COMPONENT__RI_HEALTHCARE_SELF_DURATION = 'ri-healthcare-self-duration';
export const PATH_COMPONENT__RI_HEALTHCARE_SPOUSE_DURATION = 'ri-healthcare-spouse-duration';
export const PATH_COMPONENT__RI_HEALTHCARE_DEPENDENT_DURATION = 'ri-healthcare-dependent-duration';
export const PATH_COMPONENT__RI_USE_TAX_PURCHASES = 'ri-use-tax-purchases';
export const PATH_COMPONENT__RI_USE_TAX_AMOUNT = 'ri-use-tax-amount';
export const PATH_COMPONENT__RI_DONE = 'ri-done';

export const PATH_COMPONENT__SC_USE_TAX_PURCHASES = 'sc-use-tax-purchases';
export const PATH_COMPONENT__SC_USE_TAX_AMOUNT = 'sc-use-tax-amount';
export const PATH_COMPONENT__SC_DONE = 'sc-done';

export const PATH_COMPONENT__UT_USE_TAX_PURCHASES = 'ut-use-tax-purchases';
export const PATH_COMPONENT__UT_USE_TAX_AMOUNT = 'ut-use-tax-amount';
export const PATH_COMPONENT__UT_DONE = 'ut-done';

export const PATH_COMPONENT__VT_SCHOOL_DISTRICT = 'vt-school-district';
export const PATH_COMPONENT__VT_USE_TAX_PURCHASES = 'vt-use-tax-purchases';
export const PATH_COMPONENT__VT_USE_TAX_AMOUNT = 'vt-use-tax-amount';
export const PATH_COMPONENT__VT_DONE = 'vt-done';

export const PATH_COMPONENT__VA_USE_TAX_PURCHASES = 'va-use-tax-purchases';
export const PATH_COMPONENT__VA_USE_TAX_AMOUNT = 'va-use-tax-amount';
export const PATH_COMPONENT__VA_DONE = 'va-done';

export const PATH_COMPONENT__WV_USE_TAX_PURCHASES = 'wv-use-tax-purchases';
export const PATH_COMPONENT__WV_USE_TAX_AMOUNT = 'wv-use-tax-amount';
export const PATH_COMPONENT__WV_DONE = 'wv-done';

export const PATH_COMPONENT__WI_SCHOOL_DISTRICT = 'wi-school-district';
export const PATH_COMPONENT__WI_USE_TAX_PURCHASES = 'wi-use-tax-purchases';
export const PATH_COMPONENT__WI_USE_TAX_AMOUNT = 'wi-use-tax-amount';
export const PATH_COMPONENT__WI_DONE = 'wi-done';

export const PATH_COMPONENT__ME_USE_TAX_PURCHASES = 'me-use-tax-purchases';
export const PATH_COMPONENT__ME_USE_TAX_AMOUNT = 'me-use-tax-amount';
export const PATH_COMPONENT__ME_DONE = 'me-done';

export const PATH_COMPONENT__MA_HEALTH_TAXPAYER_COVERAGE = 'ma-health-taxpayer-coverage';
export const PATH_COMPONENT__MA_HEALTH_TAXPAYER_GOVT_INSURANCE = 'ma-health-taxpayer-govt-insurance';
export const PATH_COMPONENT__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE = 'ma-health-taxpayer-private-insurance';
export const PATH_COMPONENT__MA_HEALTH_TAXPAYER_MONTHS = 'ma-health-taxpayer-months';
export const PATH_COMPONENT__MA_HEALTH_SPOUSE_COVERAGE = 'ma-health-spouse-coverage';
export const PATH_COMPONENT__MA_HEALTH_SPOUSE_GOVT_INSURANCE = 'ma-health-spouse-govt-insurance';
export const PATH_COMPONENT__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE = 'ma-health-spouse-private-insurance';
export const PATH_COMPONENT__MA_HEALTH_SPOUSE_MONTHS = 'ma-health-spouse-months';
export const PATH_COMPONENT__MA_DONE = 'ma-done';

export const SLUG__STATE_DEFAULT = 'state-default';
export const SLUG__STATE_NO_INCOME_TAX = 'state-no-income-tax';
export const SLUG__STATE_RESIDENCY = 'state-residency';
export const SLUG__STATE_RESIDENCY_LIVED_SEPARATELY = 'state-residency-lived-separately';
export const SLUG__STATE_INCOME = 'state-income';
export const SLUG__STATE_EXPENSES = 'state-expenses';
export const SLUG__STATE_RETURN = 'state-return';

export const SLUG__CA_USE_TAX_PURCHASES = 'ca-use-tax-purchases';
export const SLUG__CA_USE_TAX_AMOUNT = 'ca-use-tax-amount';
export const SLUG__CA_RENT_CREDIT = 'ca-rent-credit';
export const SLUG__CA_RENT_CREDIT_DEPENDENT = 'ca-rent-credit-dependent';
export const SLUG__CA_RENT_CREDIT_PROPERTY = 'ca-rent-credit-property';
export const SLUG__CA_RENT_CREDIT_TAX = 'ca-rent-credit-tax';
export const SLUG__CA_RENT_CREDIT_SUCCESS = 'ca-rent-credit-success';
export const SLUG__CA_HEALTHCARE = 'ca-healthcare';
export const SLUG__CA_HEALTHCARE_SELF_DURATION = 'ca-healthcare-self-duration';
export const SLUG__CA_HEALTHCARE_SPOUSE_DURATION = 'ca-healthcare-spouse-duration';
export const SLUG__CA_HEALTHCARE_DEPENDENT_DURATION = 'ca-healthcare-dependent-duration';
export const SLUG__CA_DONE = 'ca-done';

export const SLUG__DC_RENT_CREDIT_OPTIONS = 'dc-rent-credit-options';
export const SLUG__DC_RENT_CREDIT_PROPERTY_TYPE = 'dc-rent-credit-property-type';
export const SLUG__DC_RENT_CREDIT_AMOUNT = 'dc-rent-credit-amount';
export const SLUG__DC_RENT_CREDIT_LANDLORD_INFO = 'dc-rent-credit-landlord-info';
export const SLUG__DC_RENT_CREDIT_LANDLORD_STREET = 'dc-rent-credit-landlord-street';
export const SLUG__DC_RENT_CREDIT_LANDLORD_ZIP = 'dc-rent-credit-landlord-zip';
export const SLUG__DC_RENT_CREDIT_PROPERTY_TAX = 'dc-rent-credit-property-tax';
export const SLUG__DC_HEALTHCARE = 'dc-healthcare';
export const SLUG__DC_HEALTHCARE_SELF_DURATION = 'dc-healthcare-self-duration';
export const SLUG__DC_HEALTHCARE_SPOUSE_DURATION = 'dc-healthcare-spouse-duration';
export const SLUG__DC_HEALTHCARE_DEPENDENT_DURATION = 'dc-healthcare-dependent-duration';
export const SLUG__DC_DONE = 'dc-done';

export const SLUG__HI_HOUSING_DISTRIBUTION = 'hi-housing-distribution';
export const SLUG__HI_HOUSING_INFO = 'hi-housing-info';
export const SLUG__HI_RENT_CREDIT_QUALIFY = 'hi-rent-credit-qualify';
export const SLUG__HI_RENT_CREDIT_STREET = 'hi-rent-credit-street';
export const SLUG__HI_RENT_CREDIT_APT = 'hi-rent-credit-apt';
export const SLUG__HI_RENT_CREDIT_CITY = 'hi-rent-credit-city';
export const SLUG__HI_RENT_CREDIT_ZIP = 'hi-rent-credit-zip';
export const SLUG__HI_RENT_CREDIT_OWNER_STREET = 'hi-rent-credit-owner-street';
export const SLUG__HI_RENT_CREDIT_OWNER_APT = 'hi-rent-credit-owner-apt';
export const SLUG__HI_RENT_CREDIT_OWNER_CITY = 'hi-rent-credit-owner-city';
export const SLUG__HI_RENT_CREDIT_OWNER_ZIP = 'hi-rent-credit-owner-zip';
export const SLUG__HI_RENT_CREDIT_INFO = 'hi-rent-credit-info';
export const SLUG__HI_DONE = 'hi-done';

export const SLUG__NY_SCHOOL_DISTRICT = 'ny-school-district';
export const SLUG__NY_COUNTY = 'ny-county';
export const SLUG__NY_LIVING_DURATION = 'ny-living-duration';
export const SLUG__NY_USE_TAX_PURCHASES = 'ny-use-tax-purchases';
export const SLUG__NY_USE_TAX_AMOUNT = 'ny-use-tax-amount';
export const SLUG__NY_DONE = 'ny-done';

export const SLUG__NJ_COUNTY = 'nj-county';
export const SLUG__NJ_HEALTHCARE = 'nj-healthcare';
export const SLUG__NJ_HEALTHCARE_SELF_DURATION = 'nj-healthcare-self-duration';
export const SLUG__NJ_HEALTHCARE_SPOUSE_DURATION = 'nj-healthcare-spouse-duration';
export const SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION = 'nj-healthcare-dependent-duration';
export const SLUG__NJ_USE_TAX_PURCHASES = 'nj-use-tax-purchases';
export const SLUG__NJ_DONE = 'nj-done';

export const SLUG__AL_USE_TAX_PURCHASES = 'al-use-tax-purchases';
export const SLUG__AL_USE_TAX_AMOUNT = 'al-use-tax-amount';
export const SLUG__AL_DONE = 'al-done';

export const SLUG__CT_USE_TAX_PURCHASES = 'ct-use-tax-purchases';
export const SLUG__CT_USE_TAX_AMOUNT = 'ct-use-tax-amount';
export const SLUG__CT_DONE = 'ct-done';

export const SLUG__ID_TUITION = 'id-tuition';
export const SLUG__ID_TUITION_AMOUNT = 'id-tuition-amount';
export const SLUG__ID_DONE = 'id-done';

export const SLUG__IL_USE_TAX_PURCHASES = 'il-use-tax-purchases';
export const SLUG__IL_USE_TAX_AMOUNT = 'il-use-tax-amount';
export const SLUG__IL_TUITION = 'il-tuition';
export const SLUG__IL_TUITION_INFO = 'il-tuition-info';
export const SLUG__IL_TUITION_DEPENDENT = 'il-tuition-dependent';
export const SLUG__IL_DONE = 'il-done';

export const SLUG__IN_COUNTY_LIVED = 'in-county-lived';
export const SLUG__IN_COUNTY_WORKED = 'in-county-worked';
export const SLUG__IN_COUNTY_SPOUSE_WORKED = 'in-county-spouse-worked';
export const SLUG__IN_SCHOOL_DISTRICT = 'in-school-district';
export const SLUG__IN_DONE = 'in-done';

export const SLUG__IA_SCHOOL_DISTRICT = 'ia-school-district';
export const SLUG__IA_DONE = 'ia-done';

export const SLUG__KY_USE_TAX_PURCHASES = 'ky-use-tax-purchases';
export const SLUG__KY_USE_TAX_PRICE = 'ky-use-tax-price';
export const SLUG__KY_REVIEW = 'ky-review';
export const SLUG__KY_DONE = 'ky-done';

export const SLUG__MI_SCHOOL_DISTRICT = 'mi-school-district';
export const SLUG__MI_USE_TAX_PURCHASES = 'mi-use-tax-purchases';
export const SLUG__MI_USE_TAX_AMOUNT = 'mi-use-tax-amount';
export const SLUG__MI_DONE = 'mi-done';

export const SLUG__MN_TUITION = 'mn-tuition';
export const SLUG__MN_TUITION_INSTITUTION = 'mn-tuition-institution';
export const SLUG__MN_TUITION_ACCOUNT = 'mn-tuition-account';
export const SLUG__MN_TUITION_AMOUNT = 'mn-tuition-amount';
export const SLUG__MN_DONE = 'mn-done';

export const SLUG__NC_USE_TAX_PURCHASES = 'nc-use-tax-purchases';
export const SLUG__NC_USE_TAX_AMOUNT = 'nc-use-tax-amount';
export const SLUG__NC_DONE = 'nc-done';

export const SLUG__NE_SCHOOL_DISTRICT = 'ne-school-district';
export const SLUG__NE_DONE = 'ne-done';

export const SLUG__ND_SCHOOL_DISTRICT = 'nd-school-district';
export const SLUG__ND_INCOME_SOURCE = 'nd-income-source';
export const SLUG__ND_DONE = 'nd-done';

export const SLUG__OH_SCHOOL_DISTRICT = 'oh-school-district';
export const SLUG__OH_USE_TAX_PURCHASES = 'oh-use-tax-purchases';
export const SLUG__OH_USE_TAX_AMOUNT = 'oh-use-tax-amount';
export const SLUG__OH_DONE = 'oh-done';

export const SLUG__OK_USE_TAX_PURCHASES = 'ok-use-tax-purchases';
export const SLUG__OK_USE_TAX_AMOUNT = 'ok-use-tax-amount';
export const SLUG__OK_DONE = 'ok-done';

export const SLUG__OR_SURPLUS_INTRO = 'or-surplus-intro';
export const SLUG__OR_SURPLUS_LIABILITY = 'or-surplus-liability';
export const SLUG__OR_SURPLUS_PRIOR_STATUS = 'or-surplus-prior-status';
export const SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE = 'or-surplus-liability-amount-single';
export const SLUG__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED = 'or-surplus-liability-amount-newly-married';
export const SLUG__OR_SURPLUS_LIABILITY_AMOUNT_JOINT = 'or-surplus-liability-amount-joint';
export const SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED = 'or-surplus-liability-amount-separated';
export const SLUG__OR_SURPLUS_AGI = 'or-surplus-agi';
export const SLUG__OR_DONE = 'or-done';

export const SLUG__PA_SCHOOL_DISTRICT = 'pa-school-district';
export const SLUG__PA_USE_TAX_PURCHASES = 'pa-use-tax-purchases';
export const SLUG__PA_W2_OCCUPATION = 'pa-w2-occupation';
export const SLUG__PA_DONE = 'pa-done';

export const SLUG__RI_HEALTHCARE = 'ri-healthcare';
export const SLUG__RI_HEALTHCARE_SELF_DURATION = 'ri-healthcare-self-duration';
export const SLUG__RI_HEALTHCARE_SPOUSE_DURATION = 'ri-healthcare-spouse-duration';
export const SLUG__RI_HEALTHCARE_DEPENDENT_DURATION = 'ri-healthcare-dependent-duration';
export const SLUG__RI_USE_TAX_PURCHASES = 'ri-use-tax-purchases';
export const SLUG__RI_USE_TAX_AMOUNT = 'ri-use-tax-amount';
export const SLUG__RI_DONE = 'ri-done';

export const SLUG__SC_USE_TAX_PURCHASES = 'sc-use-tax-purchases';
export const SLUG__SC_USE_TAX_AMOUNT = 'sc-use-tax-amount';
export const SLUG__SC_DONE = 'sc-done';

export const SLUG__UT_USE_TAX_PURCHASES = 'ut-use-tax-purchases';
export const SLUG__UT_USE_TAX_AMOUNT = 'ut-use-tax-amount';
export const SLUG__UT_DONE = 'ut-done';

export const SLUG__VT_SCHOOL_DISTRICT = 'vt-school-district';
export const SLUG__VT_USE_TAX_PURCHASES = 'vt-use-tax-purchases';
export const SLUG__VT_USE_TAX_AMOUNT = 'vt-use-tax-amount';
export const SLUG__VT_DONE = 'vt-done';

export const SLUG__VA_USE_TAX_PURCHASES = 'va-use-tax-purchases';
export const SLUG__VA_USE_TAX_AMOUNT = 'va-use-tax-amount';
export const SLUG__VA_USE_TAX_AMOUNT_SPOUSE = 'va-use-tax-amount-spouse';
export const SLUG__VA_DONE = 'va-done';

export const SLUG__WV_USE_TAX_PURCHASES = 'wv-use-tax-purchases';
export const SLUG__WV_USE_TAX_AMOUNT = 'wv-use-tax-amount';
export const SLUG__WV_DONE = 'wv-done';

export const SLUG__WI_SCHOOL_DISTRICT = 'wi-school-district';
export const SLUG__WI_USE_TAX_PURCHASES = 'wi-use-tax-purchases';
export const SLUG__WI_USE_TAX_AMOUNT = 'wi-use-tax-amount';
export const SLUG__WI_DONE = 'wi-done';

export const SLUG__ME_USE_TAX_PURCHASES = 'me-use-tax-purchases';
export const SLUG__ME_USE_TAX_AMOUNT = 'me-use-tax-amount';
export const SLUG__ME_USE_TAX_AMOUNT_SPOUSE = 'me-use-tax-amount-spouse';
export const SLUG__ME_DONE = 'me-done';

export const SLUG__MA_HEALTH_TAXPAYER_COVERAGE = 'ma-health-taxpayer-coverage';
export const SLUG__MA_HEALTH_TAXPAYER_GOVT_INSURANCE = 'ma-health-taxpayer-govt-insurance';
export const SLUG__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE = 'ma-health-taxpayer-private-insurance';
export const SLUG__MA_HEALTH_TAXPAYER_MONTHS = 'ma-health-taxpayer-months';
export const SLUG__MA_HEALTH_SPOUSE_COVERAGE = 'ma-health-spouse-coverage';
export const SLUG__MA_HEALTH_SPOUSE_GOVT_INSURANCE = 'ma-health-spouse-govt-insurance';
export const SLUG__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE = 'ma-health-spouse-private-insurance';
export const SLUG__MA_HEALTH_SPOUSE_MONTHS = 'ma-health-spouse-months';
export const SLUG__MA_DONE = 'ma-done';

export const COLLECTION_TYPE__STATE_RETURN = 'state-return';
export const COLLECTION_TYPE__STATE_RESIDENCY = 'state-residency';
export const COLLECTION_TYPE__STATE_INCOME = 'state-income';
export const COLLECTION_TYPE__STATE_EXPENSES = 'state-expenses';
export const COLLECTION_TYPE__DC_RENT_CREDIT = 'dc-rent-credit';
export const COLLECTION_TYPE__MA_HEALTH = 'ma-health';

export const ENDPOINT_ATTRIBUTE__STATE_RETURN = 'state-return';
export const ENDPOINT_ATTRIBUTE__STATE_RETURN_DONE = 'state-return-done';
export const ENDPOINT_ATTRIBUTE__RESIDENCY_FIRST_STATE = 'state-residency-1';
export const ENDPOINT_ATTRIBUTE__RESIDENCY_SECOND_STATE = 'state-residency-2';
export const ENDPOINT_ATTRIBUTE__DC_RENT_CREDIT_PROPERTY_TYPE = 'dc-rent-credit-property-type';
export const ENDPOINT_ATTRIBUTE__MA_HEALTH_TAXPAYER_COVERAGE = 'ma-health-taxpayer-coverage';
export const ENDPOINT_ATTRIBUTE__MA_HEALTH_SPOUSE_COVERAGE = 'ma-health-spouse-coverage';

export const SLUG__TEMPLATE_STATE_DONE = 'template-state-done';
