import React, { useEffect } from 'react';
import _ from 'lodash';
import Link from '@mui/material/Link';
import { connect } from 'react-redux';
import { useFilePicker } from 'use-file-picker';
import { setDropzoneFiles } from '@app/src/actions/taxFlowActions';
import { dropzoneFilesSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowFormCaptureItem.scss';

const TaxFlowFormCaptureItem = ({ history, dropzoneFiles, setDropzoneFiles }) => {
  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    accept: 'image/*',
    readAs: 'BinaryString',
    maxFileSize: 10,
    multiple: false,
    initializeWithCustomParameters: (input) => {
      input.setAttribute('capture', '');
    }
  });

  useEffect(() => {
    if (!_.isEmpty(plainFiles)) {
      setDropzoneFiles([...dropzoneFiles, ...plainFiles]);
      clear();
    }
  }, [plainFiles, dropzoneFiles, clear, setDropzoneFiles]);

  useEffect(() => {
    if (_.isEmpty(dropzoneFiles)) {
      history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
    }
  }, [dropzoneFiles, history]);

  const addAnotherPage = () => {
    openFileSelector();
  };

  return (
    <>
      <div className='tax-flow-form-capture-item-image-container'>
        {dropzoneFiles.map((file, idx) => (
          <div key={`photo_capture_${idx}`} className='tax-flow-form-capture-item-image-container-image'>
            <img
              className='tax-flow-form-capture-item-image-container-image-content'
              src={URL.createObjectURL(file)}
            ></img>
          </div>
        ))}
      </div>
      <Link
        className='tax-flow-form-capture-item-add-page'
        component='button'
        underline='none'
        onClick={addAnotherPage}
      >
        <div>Add another page</div>
      </Link>
    </>
  );
};

const mapDispatchToProps = { setDropzoneFiles };

const mapStateToProps = (state, props) => ({ history: props.history, dropzoneFiles: dropzoneFilesSelector(state) });

const ConnectedTaxFlowFormCaptureItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormCaptureItem);

export default ConnectedTaxFlowFormCaptureItem;
