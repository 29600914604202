import React from 'react';
import _ from 'lodash';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import { ArrowDown2, ArrowRight2 } from 'iconsax-react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setHolisticOnboardingDrawerOpen } from '@app/src/actions/holisticOnboardingActions';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { drawerOpenSelector, learnMoreSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const InfoDrawer = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const learnMoreInfo = useSelector((state) => learnMoreSelector(state, location));
  const drawerOpen = useSelector(drawerOpenSelector);

  const title = _.get(learnMoreInfo, 'title', '');
  const contentType = _.get(learnMoreInfo, 'contentType', '');
  const accordionInfo = _.get(learnMoreInfo, 'accordionInfo', {});
  const isMobile = useIsMobile();

  const onClose = () => {
    dispatch(setHolisticOnboardingDrawerOpen(false));
  };

  const Accordion = styled((props) => <MuiAccordion {...props} />)(() => ({
    boxShadow: 'none',
    border: 'none',
    '& .MuiExpanded': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '30px',
      marginBottom: '0px'
    }
  }));

  const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(() => ({
    boxShadow: 'none',
    border: 'none',
    paddingLeft: '0px',
    marginBottom: '16px',
    minHeight: '0px',
    '& .MuiAccordionSummary-content': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px'
    }
  }));

  const AccordionDetails = styled((props) => <MuiAccordionDetails {...props} />)(() => ({
    padding: '0px 16px 16px 0px'
  }));

  const content = (
    <>
      {contentType === 'accordion' && (
        <div>
          <ReactMarkdown
            source={_.get(accordionInfo, ['intro'], '')}
            className='taxflow-info-modal-window-accordion-intro'
          />
          {accordionInfo.data.map((info, i) => {
            return (
              <Accordion
                sx={{
                  '&:before': {
                    display: 'none'
                  }
                }}
                key={i + 1}
              >
                <AccordionSummary
                  expandIcon={<ArrowDown2 />}
                  aria-controls={`panel${i}a-content`}
                  id={`panel${i + 1}a-header`}
                >
                  <ReactMarkdown className='taxflow-info-modal-window-accordion-summary' source={info.summary} />
                </AccordionSummary>
                <AccordionDetails>
                  <ReactMarkdown className='taxflow-info-modal-window-accordion-details' source={info.details} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </>
  );

  return (
    accordionInfo && (
      <SwipeableDrawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={drawerOpen}
        onOpen={() => {}}
        onClose={onClose}
        PaperProps={{ style: { maxWidth: '600px' } }}
      >
        <div style={{ padding: '16px', marginTop: '16px' }}>
          <IconButton onClick={onClose} style={{ position: 'absolute', right: '0px', top: '0px', margin: '8px' }}>
            {isMobile ? <ArrowDown2 /> : <ArrowRight2 />}
          </IconButton>
          <h1 className='taxflow-info-modal-window-panel-header'> {title}</h1>
          <div className='taxflow-info-modal-window-panel-text'>{content}</div>
        </div>
      </SwipeableDrawer>
    )
  );
};

export default InfoDrawer;
