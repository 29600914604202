// path components
export const PATH_COMPONENT__SELF_DETAIL = 'self-detail';
export const PATH_COMPONENT__SELF_MARRIAGE = 'self-marriage';
export const PATH_COMPONENT__SELF_ID = 'self-id';
export const PATH_COMPONENT__SELF_ID_INFO = 'self-id-info';
export const PATH_COMPONENT__SPOUSE_ID = 'spouse-id';
export const PATH_COMPONENT__SPOUSE_ID_INFO = 'spouse-id-info';
export const PATH_COMPONENT__SPOUSE_DETAIL = 'spouse-detail';
export const PATH_COMPONENT__TAX_HOME_ADDRESS_DETAIL = 'home-address-detail';
export const PATH_COMPONENT__DEPENDENTS = 'dependents';
export const PATH_COMPONENT__DEPENDENT_DETAIL = 'dependent-detail';
export const PATH_COMPONENT__DEPENDENT_RELATIONSHIP_DETAIL = 'dependent-relationship-detail';
export const PATH_COMPONENT__DEPENDENT_QUALIFIED = 'dependent-qualified';
export const PATH_COMPONENT__DEPENDENT_UNQUALIFIED = 'dependent-unqualified';
export const PATH_COMPONENT__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL = 'dependent-relationship-relative-detail';
export const PATH_COMPONENT__SELF_DUE_DILIGENCE_INFO = 'self-due-diligence-info';
export const PATH_COMPONENT__SPOUSE_DUE_DILIGENCE_INFO = 'spouse-due-diligence-info';

// slugs
export const SLUG__SELF_DETAIL = 'self-detail';
export const SLUG__SELF_ID = 'self-id';
export const SLUG__SELF_ID_INFO = 'self-id-info';
export const SLUG__SELF_MARRIAGE = 'self-marriage';
export const SLUG__SELF_MARRIAGE_STATUS = 'self-marriage-status';
export const SLUG__SELF_MARRIAGE_FILING_OPTION = 'self-marriage-filing-option';
export const SLUG__SELF_DUE_DILIGENCE_INFO = 'self-due-diligence-info';
export const SLUG__SELF_FIRST_NAME = 'self-first-name';
export const SLUG__SELF_LAST_NAME = 'self-last-name';
export const SLUG__SELF_TAX_STATUS = 'self-tax-status';
export const SLUG__SPOUSE_DETAIL = 'spouse-detail';
export const SLUG__SPOUSE_RIP_DATE = 'spouse-rip-date';
export const SLUG__SPOUSE_ID = 'spouse-id';
export const SLUG__SPOUSE_ID_INFO = 'spouse-id-info';
export const SLUG__HOME_ADDRESS_DETAIL = 'home-address-detail';
export const SLUG__HOME_ADDRESS_STREET = 'home-address-street';
export const SLUG__HOME_ADDRESS_APT = 'home-address-apt';
export const SLUG__HOME_ADDRESS_CITY = 'home-address-city';
export const SLUG__HOME_ADDRESS_STATE = 'home-address-state';
export const SLUG__HOME_ADDRESS_MULTISTATE = 'home-address-multistate';
export const SLUG__HOME_ADDRESS_MULTISTATE_HEADER = 'home-address-multistate-header';
export const SLUG__HOME_ADDRESS_STREET_MULTISTATE = 'home-address-street-multistate';
export const SLUG__HOME_ADDRESS_APT_MULTISTATE = 'home-address-apt-multistate';
export const SLUG__HOME_ADDRESS_CITY_MULTISTATE = 'home-address-city-multistate';
export const SLUG__HOME_ADDRESS_ZIP_MULTISTATE = 'home-address-zip-multistate';
export const SLUG__HOME_ADDRESS_STATE_MULTISTATE = 'home-address-state-multistate';
export const SLUG__DEPENDENTS = 'dependents';
export const SLUG__DEPENDENT_DETAIL = 'dependent-detail';
export const SLUG__DEPENDENT_RELATIONSHIP_TYPE = 'dependent-relationship-type';
export const SLUG__DEPENDENT_RELATIONSHIP_OTHER = 'dependent-relationship-other';
export const SLUG__DEPENDENT_RELATIONSHIP_DETAIL = 'dependent-relationship-detail';
export const SLUG__DEPENDENT_QUALIFIED = 'dependent-qualified';
export const SLUG__DEPENDENT_UNQUALIFIED = 'dependent-unqualified';
export const SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL = 'dependent-relationship-relative-detail';
export const SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL_REASON = 'dependent-relationship-relative-detail-reason';
export const SLUG__HOME_ADDRESS_ZIP = 'home-address-zip';
export const SLUG__SELF_BIRTHDATE = 'self-birthdate';
export const SLUG__SPOUSE_BIRTHDATE = 'spouse-birthdate';
export const SLUG__DEPENDENT_BIRTHDATE = 'dependent-birthdate';
export const SLUG__SELF_ID_STATE = 'self-id-state';
export const SLUG__SELF_ID_DOCUMENT_NUMBER = 'self-id-document-number';
export const SLUG__SELF_ID_ISSUE_DATE = 'self-id-issue-date';
export const SLUG__SELF_ID_EXPIRY_DATE = 'self-id-expiry-date';
export const SLUG__SPOUSE_ID_STATE = 'spouse-id-state';
export const SLUG__SPOUSE_ID_DOCUMENT_NUMBER = 'spouse-id-document-number';
export const SLUG__SPOUSE_ID_ISSUE_DATE = 'spouse-id-issue-date';
export const SLUG__SPOUSE_ID_EXPIRY_DATE = 'spouse-id-expiry-date';
export const SLUG__SPOUSE_DUE_DILIGENCE_INFO = 'spouse-due-diligence-info';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS = 'self-tax-status';
export const ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_STATUS = 'self-marriage-status';
export const ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION = 'self-marriage-filing-option';
export const ENDPOINT_ATTRIBUTE__SELF_FIRST_NAME = 'self-first-name';
export const ENDPOINT_ATTRIBUTE__SELF_LAST_NAME = 'self-last-name';
export const ENDPOINT_ATTRIBUTE__SELF_SSN = 'self-ssn';
export const ENDPOINT_ATTRIBUTE__SPOUSE_FIRST_NAME = 'spouse-first-name';
export const ENDPOINT_ATTRIBUTE__SPOUSE_LAST_NAME = 'spouse-last-name';
export const ENDPOINT_ATTRIBUTE__SPOUSE_SSN = 'spouse-ssn';
export const ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE = 'home-address-state';
export const ENDPOINT_ATTRIBUTE__HOME_ADDRESS_MULTISTATE = 'home-address-multistate';
export const ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE_MULTISTATE = 'home-address-state-multistate';
export const ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME = 'dependent-first-name';
export const ENDPOINT_ATTRIBUTE__DEPENDENT_STARTED = 'dependent-started';
export const ENDPOINT_ATTRIBUTE__DEPENDENT_QUALIFIED = 'dependent-qualified';

// collection types
export const COLLECTION_TYPE__SELF = 'self';
export const COLLECTION_TYPE__SPOUSE = 'spouse';
export const COLLECTION_TYPE__HOME_ADDRESS = 'home-address';
export const COLLECTION_TYPE__DEPENDENT = 'dependent';
