import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { usePlaidLink } from 'react-plaid-link';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import { setLinkToken } from '@app/src/actions/bankActions';
import { trackActivity } from '@app/src/services/analyticsService';
import { runOnSuccess, runOnExit, runOnEvent } from '@app/src/services/bankLinkService';

const OnboardingOauth = ({ history, linkToken, setLinkToken, runOnSuccess, runOnExit, runOnEvent }) => {
  useEffect(() => {
    const linkToken = localStorage.getItem('keeper_link_token');
    trackActivity('bank link: oauth', {
      linkToken,
      receivedRedirectUri: window.location.href
    });
    setLinkToken(linkToken);
  }, [setLinkToken]);

  const onSuccess = useCallback(
    async (publicToken, metadata) => {
      await runOnSuccess({ publicToken, metadata });
      history.push('/onboarding/link');
    },
    [runOnSuccess, history]
  );

  const onEvent = useCallback(
    async (eventName, metadata) => {
      await runOnEvent({ eventName, metadata });
    },
    [runOnEvent]
  );

  const onExit = useCallback(
    async (error, metadata) => {
      await runOnExit({ error, metadata });
      history.push('/onboarding/link');
    },
    [runOnExit, history]
  );

  const config = {
    token: linkToken,
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit,
    onEvent
  };

  const { open, ready } = usePlaidLink(config);

  trackActivity('bank link: usePlaidLink called', {
    token: linkToken,
    receivedRedirectUri: window.location.href,
    ready
  });

  useEffect(() => {
    if (ready) {
      trackActivity('bank link: usePlaidLink ready');
      open();
    }
  }, [open, ready]);

  return (
    <div className='oauth'>
      <Loader />
    </div>
  );
};

const mapStateToProps = (state) => ({
  linkToken: _.get(state, ['bank', 'linkToken'])
});

const mapDispatchToProps = {
  setLinkToken,
  runOnSuccess,
  runOnExit,
  runOnEvent
};

const ConnectedOnboardingOauth = connect(mapStateToProps, mapDispatchToProps)(OnboardingOauth);

export default ConnectedOnboardingOauth;
