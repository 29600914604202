import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetFeaturesQuery } from '@app/src/api/profileApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const TAX_YEAR_LIFECYCLE_STAGES = {
  NOT_OPEN: 'not_open',
  BULK_ONLY: 'bulk_only',
  PARTIAL: 'partial',
  FULL: 'full',
  CLOSED: 'closed'
};

export const useTaxYearLifecycle = ({ taxYear } = {}) => {
  const selectedYear = useSelector(yearSelector);
  const year = _.defaultTo(taxYear, selectedYear);
  const { data: features, isLoading: featuresLoading } = useGetFeaturesQuery();

  if (featuresLoading) {
    return { isLoading: true };
  }

  if (_.some(features, { name: `ty${year}-closed`, value: 1 })) {
    return { stage: TAX_YEAR_LIFECYCLE_STAGES.CLOSED, isLoading: false };
  }

  if (_.some(features, { name: `ty${year}-full`, value: 1 })) {
    return { stage: TAX_YEAR_LIFECYCLE_STAGES.FULL, isLoading: false };
  }

  if (_.some(features, { name: `ty${year}-partial`, value: 1 })) {
    return { stage: TAX_YEAR_LIFECYCLE_STAGES.PARTIAL, isLoading: false };
  }

  if (_.some(features, { name: `ty${year}-bulk-only`, value: 1 })) {
    return { stage: TAX_YEAR_LIFECYCLE_STAGES.BULK_ONLY, isLoading: false };
  }

  return { stage: TAX_YEAR_LIFECYCLE_STAGES.NOT_OPEN, isLoading: false };
};
