import _ from 'lodash';
import moment from 'moment';
import { useUIState } from '@app/src/hooks/useUIState';
import useYears from '@app/src/hooks/useYears';

/** Subscribe to the UI states for all supported years - this ensures cleaner + quicker transitions between tax years, as data for all years is more up to date */
export const useAllYearUIStates = () => {
  const years = useYears();

  // TODO: need to refactor when we support > 2 years
  // should not conditionally call hooks in a loop
  useUIState({ taxYear: years[0] ?? _.toString(moment().year() - 1) });
  useUIState({ taxYear: years[1] ?? _.toString(moment().year()) });
};
