import React from 'react';
import { Link } from 'react-router-dom';
import AccountItem from '@app/src/Components/Bank/AccountItem';
import ArrowRightIcon from '@app/src/assets/arrow-right.svg?react';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import CreditCardIcon from '@app/src/assets/card.svg?react';
import '@app/src/Components/Bank/LinkedAccount/LinkedAccount.scss';

const LinkedAccount = ({
  institutionName,
  logo,
  linked,
  accountType,
  itemId,
  subAccounts,
  setSubaccount,
  changeAccountType,
  setSelfUnlockModalOpen,
  isInConfirmAmounts,
  origin,
  type,
  ...props
}) => (
  <div className='linked-account'>
    <div className='linked-account-logo-container'>
      {logo ? (
        <img className='linked-account-logo' src={`data:image/png;base64,${logo}`} alt='Bank logo' />
      ) : (
        <div className='linked-account-logo-default'>
          {accountType === 'credit' ? <CreditCardIcon /> : <BankIcon />}
        </div>
      )}
    </div>
    <div className='linked-account-heading'>
      <span className='linked-account-name'>{institutionName}</span>
    </div>
    <div className='linked-account-details'>
      {linked === 0 && origin === 'settings' ? (
        <div className='unlinked-item'>
          <Link to={`/bank-details/${itemId}`} className='unlinked-item-link'>
            <span className='unlinked-item-link-text'>This account has unlinked from Keeper. Relink now!</span>
            <ArrowRightIcon />
          </Link>
        </div>
      ) : (
        subAccounts.map((subAcc, idx) => (
          <AccountItem
            isInConfirmAmounts={isInConfirmAmounts}
            account={subAcc}
            key={idx}
            isSettings={type === 'settings'}
            setSelfUnlockModalOpen={setSelfUnlockModalOpen}
            onDisconnect={() => setSubaccount(subAcc)}
            onChangeAccountType={changeAccountType}
            {...props}
          />
        ))
      )}
    </div>
  </div>
);

export default LinkedAccount;
