import React, { useEffect } from 'react';
import _ from 'lodash';
import LinearProgress from '@mui/material/LinearProgress';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { useUIState } from '@app/src/hooks/useUIState';
import { colorNeutralGainsboro, colorSuccess } from '@app/src/theme';

const ProgressBar = ({ sections }) => {
  const isMobile = useIsMobile();

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', gap: 24 }}>
      {_.map(sections, ({ progress, weight, getText }) => {
        const text = getText({ isMobile });
        return (
          <div key={text} style={{ flex: 1, flexGrow: weight / _.sumBy(sections, 'weight') }}>
            <LinearProgress
              sx={{
                backgroundColor: colorNeutralGainsboro,
                color: colorSuccess,
                borderRadius: 2,
                height: 8,
                '& .MuiLinearProgress-bar': { borderRadius: 5, backgroundColor: colorSuccess }
              }}
              variant='determinate'
              value={progress}
            />
            <div style={{ width: '100%', textAlign: 'center' }}>{text}</div>
          </div>
        );
      })}
    </div>
  );
};

const TaxFlowProgressBar = ({ setLoading = _.noop }) => {
  const { uiState, isLoading } = useUIState();

  useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

  if (isLoading) {
    return null;
  }

  return <ProgressBar sections={uiState.sections} />;
};

export default TaxFlowProgressBar;
