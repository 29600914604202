import _ from 'lodash';
import { SUBSCRIPTION_PLANS, SUBSCRIPTION_STATUSES } from '@app/src/constants/constants';

export const useShouldShowPremiumPaywall = ({ subscriptionInfo, reviewPills }) => {
  const hasPremiumPill = _.chain(reviewPills)
    .flatMap((pill) => pill.pills)
    .some({ isPremium: true })
    .value();
  const hasActivePremiumSubscription =
    _.get(subscriptionInfo, 'subscription.status') === SUBSCRIPTION_STATUSES.ACTIVE &&
    _.get(subscriptionInfo, 'subscription.type') === SUBSCRIPTION_PLANS.PREMIUM;
  return hasPremiumPill && !hasActivePremiumSubscription;
};
