import React, { useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ArrowDown2, Calendar } from 'iconsax-react';
import moment from 'moment';
import KeeperSelect from '@app/src/Components/Common/KeeperSelect/KeeperSelect';
import { colorPrimaryAlt, colorSecondary, colorSurface } from '@app/src/theme';
import '@app/src/Components/Common/YearDropdown/YearDropdown.scss';

/** @type {typeof MenuItem} */
const YearDropdownMenuItem = (props) => {
  return (
    <MenuItem
      sx={{
        '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible': {
          backgroundColor: colorPrimaryAlt,
          color: colorSurface
        }
      }}
      {...props}
    />
  );
};

const ArrowIcon = (props) => (
  <div {...props} className={'icon-container ' + props.className}>
    <ArrowDown2 color={colorSurface} />
  </div>
);

/**
 * @typedef YearDropdownProps
 * @property {string[]} [years]
 * @property {string} [defaultValue]
 */

/** @type {React.FC<YearDropdownProps & React.ComponentProps<typeof KeeperSelect>} */
const YearDropdown = ({
  years = [moment().year().toString(), (moment().year() + 1).toString()],
  defaultValue = '2024',
  ...props
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!ref.current) return;

    const element = ref.current;

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length !== 1) {
        setMenuWidth(null);
        return;
      }

      const entry = entries[0];

      setMenuWidth(entry?.contentRect?.width);
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className='year-dropdown-container' ref={ref}>
      <KeeperSelect
        startAdornment={
          <div className='icon-container calendar' onClick={handleOpen}>
            <Calendar color={colorSurface} />
          </div>
        }
        onClick={handleToggle}
        open={open}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          },
          '.MuiSelect-icon': { right: '16px', top: 'unset' },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorSecondary,
            borderWidth: '2px'
          }
        }}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={ArrowIcon}
        MenuProps={{
          className: 'year-dropdown-menu',
          MenuListProps: {
            className: 'menu-list',
            ...(menuWidth && {
              sx: {
                width: `${menuWidth}px`
              }
            })
          },
          anchorEl: () => ref?.current,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        }}
        aria-label='Year'
        defaultValue={defaultValue}
        className='year-dropdown'
        {...props}
      >
        {years.map((year) => (
          <YearDropdownMenuItem key={year} value={year}>
            {year}
          </YearDropdownMenuItem>
        ))}
      </KeeperSelect>
    </div>
  );
};

export default YearDropdown;
