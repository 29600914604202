import React, { useState } from 'react';
import url from 'url';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import { setContextLoading } from '@app/src/actions/taxFlowActions';
import {
  useGetHomeAddressPreselectOptionsQuery,
  useGetHomePillsQuery,
  useUpdateTaxDataMutation
} from '@app/src/api/taxDataApi';
import history from '@app/src/keeperHistory';
import { isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { advanceToNextQuestionnaireQuestion } from '@app/src/taxflow/main/services/mainService';
import { getNextCollectionId } from '@app/src/taxflow/main/services/taxFlowDataService';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { HOME_SLUGS, COLLECTION_TYPE__HOME, HOME_PATH_COMPONENTS } from '@app/src/taxflow/sections/home/homeConstants';
import { getSlugMap } from '@app/src/taxflow/sections/home/homeUtils';
import { DEFAULT_COLLECTION_ID, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowHomePills = ({ isQuestionnaireFlow, advanceToNextQuestionnaireQuestion }) => {
  const dispatch = useDispatch();
  const year = useSelector(yearSelector);

  const { data: homePills, isLoading: homePillsLoading } = useGetHomePillsQuery({ year });
  const { data: preselectOptions, isLoading: preselectOptionsLoading } = useGetHomeAddressPreselectOptionsQuery({
    year
  });

  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [deleteModalTargetCollectionId, setDeleteModalTargetCollectionId] = useState();

  const [updateTaxData] = useUpdateTaxDataMutation();
  if (homePillsLoading || preselectOptionsLoading) {
    return null;
  }

  const confirmPillDeletion = ({ collectionId, text }) => {
    trackActivity('home: pill removal requested', { collectionId, pillName: text });
    setShowCollectionDeleteModal(true);
    setDeleteModalTargetCollectionId(collectionId);
  };

  const onClickHomePill = async ({ startSlug, collectionId, text }) => {
    trackActivity('home: pill clicked', { collectionId, pillName: text });
    const possibleNextPathComponent = getPathComponentBySlug(startSlug);
    if (_.isNil(possibleNextPathComponent)) {
      defaultCaptureMessage('Could not advance to next home question', {
        coll_id: collectionId,
        slug: startSlug
      });
      return;
    }
    dispatch(setContextLoading(true));
    await updateTaxData({
      taxData: [
        {
          coll_id: DEFAULT_COLLECTION_ID,
          coll_type: COLLECTION_TYPE__HOME,
          slug: HOME_SLUGS.NAV_START,
          value: '1'
        }
      ],
      generateSharedCollectionId: false,
      year
    });

    const nextCollectionId = await dispatch(getNextCollectionId({ collectionType: COLLECTION_TYPE__HOME }));
    // We only route to the preselect screen when both these conditions are met:
    //   1. There are preselect options left and
    //   2. The user is trying to add a new pill (the collectionId == nextCollectionId)
    // If the collectionId != nextCollectionId, it means the user is trying to edit an existing pill,
    // in which case we may want them to route to the address screen instead. This logic is driven by the backend.
    const shouldRouteToPreselectScreen = preselectOptions.length > 0 && collectionId == nextCollectionId;
    const nextPathComponent = shouldRouteToPreselectScreen
      ? HOME_PATH_COMPONENTS.PRESELECT_ADDRESS
      : possibleNextPathComponent;

    if (isQuestionnaireFlow) {
      const slugMap = getSlugMap();
      await advanceToNextQuestionnaireQuestion({
        nextSlug: slugMap[nextPathComponent],
        nextCollectionId: collectionId,
        questionnaireEarlyExit: false,
        history
      });
    } else {
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: collectionId === '0' ? undefined : collectionId
          }
        })
      );
    }
  };

  const deriveVariant = (pill) => {
    if (pill.warning) {
      return 'warning';
    }
    if (pill.addable) {
      return 'add';
    }
    return 'default';
  };

  return homePills.map((pill, index) => {
    const { collectionId, text } = pill;
    return (
      <div key={index}>
        <TaxFlowPill
          text={pill.text}
          removable={pill.removable}
          variant={deriveVariant(pill)}
          onClick={() => onClickHomePill(pill)}
          onRemove={() => confirmPillDeletion({ collectionId, text })}
        />
        {showCollectionDeleteModal && (
          <TaxFlowCollectionDeleteModal
            collectionType={COLLECTION_TYPE__HOME}
            collectionId={deleteModalTargetCollectionId}
            hideModal={() => setShowCollectionDeleteModal(false)}
          />
        )}
      </div>
    );
  });
};

const mapStateToProps = (state) => {
  return {
    isQuestionnaireFlow: isQuestionnaireFlowSelector(state)
  };
};

const mapDispatchToProps = {
  advanceToNextQuestionnaireQuestion
};

const ConnectedTaxFlowHomePills = connect(mapStateToProps, mapDispatchToProps)(TaxFlowHomePills);

export default ConnectedTaxFlowHomePills;
