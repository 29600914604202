import React, { Component } from 'react';
import _ from 'lodash';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { connect } from 'react-redux';
import TaxFlowInfoModal from '@app/src/Components/TaxFlow/Common/TaxFlowInfoModal';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import { S3_COMPOSITE_FORMS_URL } from '@app/src/constants/constants';
import { trackActivity } from '@app/src/services/analyticsService';
import { requestInfoResponseMessage } from '@app/src/services/assistantService';
import { formUploadAttemptsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { currentCollectionIdSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { colorPrimaryAlt } from '@app/src/theme';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

class TaxFlowInfoButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compositeFieldTitle: '',
      compositeUrl: ''
    };
  }

  async componentDidMount() {
    const { currentQuestion, formUploadAttempts, currentCollectionId } = this.props;
    const compositeFieldTitle = _.get(currentQuestion, ['question_meta_ext', 'compositeFieldTitle']);
    const compositeFieldId = _.get(currentQuestion, ['question_meta_ext', 'compositeFieldId']);
    const compositeFormBrokerage = _.get(formUploadAttempts, [
      currentQuestion.collectionType,
      currentCollectionId,
      'brokerage'
    ]);
    if (compositeFieldTitle && compositeFieldId && compositeFormBrokerage) {
      const compositeUrl = `${S3_COMPOSITE_FORMS_URL}${compositeFieldId}_${compositeFormBrokerage}.png`;
      try {
        const imageHead = await axios.head(compositeUrl, {
          headers: { Authorization: '' }
        });
        if (_.get(imageHead, 'status') === 200) {
          this.setState({
            compositeFieldTitle,
            compositeUrl
          });
        }
      } catch (e) {
        // swallow 403 errors, which are expected since not all brokerages have sections for each form
        if (_.get(e, ['response', 'status']) !== 403) {
          defaultCaptureException(e);
        }
      }
    }
  }

  deriveTaxFlowInfoItem({ currentQuestion, item, taxFlow }) {
    const info = item?.info ?? currentQuestion?.infoItem?.info;
    if (_.isNil(info)) {
      return null;
    }
    return (
      _.get(currentQuestion, ['info', info, 'fields']) ||
      _.get(taxFlow, ['normalizedInfoItems', 'byId', info, 'fields'])
    );
  }

  render() {
    const { currentQuestion, item, taxFlow, learnMoreInfoItem } = this.props;
    const { compositeUrl, compositeFieldTitle } = this.state;

    if (compositeUrl && compositeFieldTitle) {
      return <TaxFlowInfoModal title={compositeFieldTitle} compositeUrl={compositeUrl} />;
    }

    const info = learnMoreInfoItem ?? this.deriveTaxFlowInfoItem({ currentQuestion, item, taxFlow });

    if (_.isNil(info)) {
      return null;
    }

    const message = _.get(info, ['title'], '');
    const response = _.get(info, ['description'], '');
    const image = _.get(info, ['image', 'fields']);
    const slug = _.get(info, ['slug']);

    const handleClick = async () => {
      const { requestInfoResponseMessage } = this.props;

      trackActivity('tax filing option info button clicked', {
        question: _.get(currentQuestion, ['slug']),
        info: _.get(info, ['slug']),
        title: message
      });

      await requestInfoResponseMessage({
        message,
        response,
        image,
        origin: 'tax filing option info button clicked',
        slug
      });
    };

    return (
      <IconButton className='checkbox-item-icon-info-button' sx={{ position: 'absolute' }} onClick={handleClick}>
        <HelpSupportIcon stroke={colorPrimaryAlt} />
      </IconButton>
    );
  }
}

const mapStateToProps = (state) => ({
  formUploadAttempts: formUploadAttemptsSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  taxFlow: state.taxFlow
});

const mapDispatchToProps = {
  requestInfoResponseMessage
};

const ConnectedTaxFlowInfoButton = connect(mapStateToProps, mapDispatchToProps)(TaxFlowInfoButton);

export default ConnectedTaxFlowInfoButton;
